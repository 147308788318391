import React, {useMemo, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import FooterButton from '@component/footer/FooterButton'
import Link from 'next/link'
import XImageSource from '@svg/sns/ic_x.svg'
import TelegramImageSource from '@svg/sns/ic_telegram.svg'
import DiscordImageSource from '@svg/sns/ic_discord.svg'
import logo_light from '@image/lot_logo.png'
import logo_dark from '@image/lot_logo_white.png'
import FooterBusinessRegistrationModal from '@feature/common/components/FooterBusinessRegistrationModal/FooterBusinessRegistrationModal'
import {useRouter} from 'next/router'
import {createLog} from '@util/logs'
import {getLinkUrl, URL_ACCOUNT_TERM_CONDITIONS, URL_ACCOUNT_TERM_PRIVACY} from '@constant/url'
import ic_apple_ko from '@image/ic_apple_ko.webp'
import ic_apple_en from '@image/ic_apple_en.webp'
import ic_android_ko from '@image/ic_android_ko.webp'
import ic_android_en from '@image/ic_android_en.webp'
import ic_apple_ko_dark from '@image/ic_apple_ko_dark.webp'
import ic_apple_en_dark from '@image/ic_apple_en_dark.webp'
import ic_android_ko_dark from '@image/ic_android_ko_dark.webp'
import ic_android_en_dark from '@image/ic_android_en_dark.webp'

interface IProps {
    className?: string
    theme?: 'light' | 'dark'
}

const ICON_SIZE = 36
const Footer: React.FC<IProps> = ({className, theme = 'light'}) => {
    const router = useRouter()
    const {t} = useTranslation()
    const modalRef = useRef<any>()

    const borderClassName = useMemo(() => {
        switch (theme) {
            case 'light':
                return 'border-t border-gray07 dark:border-dark_gray07'
            case 'dark':
                return 'border-t border-gray01'
        }
    }, [theme])

    const navTextColorClassName = useMemo(() => {
        switch (theme) {
            case 'light':
                return 'text-gray01 dark:text-dark_gray01'
            case 'dark':
                return 'text-gray04'
        }
    }, [theme])

    const copyRightTextColorClassName = useMemo(() => {
        switch (theme) {
            case 'light':
                return 'text-gray02 dark:text-dark_gray02'
            case 'dark':
                return 'text-gray04'
        }
    }, [theme])

    const googleAppleTextColorClassName = useMemo(() => {
        switch (theme) {
            case 'light':
                return 'text-gray03 dark:text-dark_gray03'
            case 'dark':
                return 'text-gray04'
        }
    }, [theme])

    const onClickSns = (sns: 'twitter' | 'telegram' | 'facebook' | 'instagram') => {
        createLog('event', 'footer_click_sns', {sns})
    }

    return (
        <footer
            className={`pt-[20px] pb-[60px] sm:pb-[30px] px-[20px] bg-white dark:bg-bg_dark_background ${className} ${borderClassName} overflow-hidden`}>
            <div className={'grid grid-cols-[auto_220px] md:grid-cols-1 gap-y-[30px] gap-x-[50px]'}>
                <div>
                    <WizImage src={logo_light} width={165} containerClassName={'dark:hidden'} />
                    <WizImage src={logo_dark} width={165} containerClassName={'hidden dark:block'} />
                    <div className={'flex gap-x-[10px] mt-[15px]'}>
                        <FooterButton
                            textColorClassName={navTextColorClassName}
                            text={t('footer.menu.privacy')}
                            href={getLinkUrl(URL_ACCOUNT_TERM_PRIVACY)}
                        />
                        <FooterButton
                            textColorClassName={navTextColorClassName}
                            text={t('footer.menu.termsAndConditions')}
                            href={getLinkUrl(URL_ACCOUNT_TERM_CONDITIONS)}
                        />
                        <FooterButton
                            textColorClassName={navTextColorClassName}
                            text={t('footer.menu.helpCenter')}
                            href={t('footer.menu.helpCenter.link')}
                            target={'_blank'}
                        />
                    </div>
                    <Text className={`text-body3 ${copyRightTextColorClassName} mt-[15px]`}>
                        {t('footer.copyright')}
                    </Text>
                    <Text className={`text-body3 ${googleAppleTextColorClassName} mt-[15px]`}>
                        {t('footer.license.apple')}
                    </Text>
                    <Text className={`text-body3 ${googleAppleTextColorClassName}`}>{t('footer.license.google')}</Text>
                </div>
                <div className={'flex gap-x-[10px] items-center md:hidden'}>
                    <Link href={t('sns.twitter.url')} onClick={() => onClickSns('twitter')} target={'_blank'}>
                        <XImageSource width={ICON_SIZE} height={ICON_SIZE} />
                    </Link>
                    <Link
                        href={t('sns.telegram.url')}
                        onClick={() => onClickSns('telegram')}
                        target={'_blank'}
                        className={'rounded-full overflow-hidden'}>
                        <TelegramImageSource width={ICON_SIZE} height={ICON_SIZE} />
                    </Link>
                    <Link href={t('sns.discord.url')} target={'_blank'}>
                        <DiscordImageSource width={ICON_SIZE} height={ICON_SIZE} />
                    </Link>
                </div>
            </div>
            <div className={'flex items-center gap-x-[15px] md:gap-x-[10px] pt-[15px]'}>
                <div
                    className={'cursor-pointer'}
                    onClick={() => {
                        createLog('event', 'footer_download_apple')
                        window.open(t('download.link.apple'))
                    }}>
                    <WizImage
                        src={router?.locale === 'ko' ? ic_apple_ko : ic_apple_en}
                        width={155}
                        containerClassName={'dark:hidden'}
                    />
                    <WizImage
                        src={router?.locale === 'ko' ? ic_apple_ko_dark : ic_apple_en_dark}
                        width={155}
                        containerClassName={'hidden dark:block'}
                    />
                </div>
                <div
                    className={'cursor-pointer'}
                    onClick={() => {
                        createLog('event', 'footer_download_android')
                        window.open(t('download.link.android'))
                    }}>
                    <WizImage
                        src={router?.locale === 'ko' ? ic_android_ko : ic_android_en}
                        width={155}
                        containerClassName={'dark:hidden'}
                    />
                    <WizImage
                        src={router?.locale === 'ko' ? ic_android_ko_dark : ic_android_en_dark}
                        width={155}
                        containerClassName={'hidden dark:block'}
                    />
                </div>
            </div>
            <div className={'hidden md:flex gap-x-[10px] items-center pt-[20px]'}>
                <Link href={t('sns.twitter.url')} onClick={() => onClickSns('twitter')} target={'_blank'}>
                    <XImageSource width={ICON_SIZE} height={ICON_SIZE} />
                </Link>
                <Link href={t('sns.telegram.url')} onClick={() => onClickSns('telegram')} target={'_blank'}>
                    <TelegramImageSource
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                        className={'rounded-full overflow-hidden'}
                    />
                </Link>
                <Link href={t('sns.discord.url')} target={'_blank'}>
                    <DiscordImageSource width={ICON_SIZE} height={ICON_SIZE} />
                </Link>
            </div>
            <FooterBusinessRegistrationModal ref={modalRef} />
        </footer>
    )
}

export default React.memo(Footer)
