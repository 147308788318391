import {useRouter} from 'next/router'
import {useLoginStore} from '@store/LoginStore'
import {useLoginModalStore} from '@store/LoginModalStore'
import {equalString} from '@util/strings'
import {SubscribeType} from '../../pages/account/subscriber/index.page'
import {AnnounceType} from '@api/announce/getAnnouncements'

const useNavigation = () => {
    const router = useRouter()
    const loginUserId = useLoginStore(state => state.id)
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)

    const isNotLogin = () => {
        return !loginUserId
    }

    const redirectLogin = (redirectUrl?: string) => {
        showLoginModal(redirectUrl)
    }

    const redirectLoginWhenNotLogin = () => {
        if (isNotLogin()) {
            router?.replace('/login')
            return
        }
    }

    const goHome = () => {
        router?.push(`/`)
    }

    const goPost = (id: string) => {
        const url = `/timeline/${id}`

        router?.push(url)
    }

    const goUserPortfolio = (id?: string) => {
        const url = equalString(id, 'mine') ? `/user/${loginUserId}/portfolio` : `/user/${id}/portfolio`

        router?.push(url)
    }

    const goUserCopyfolio = (id?: string) => {
        const url = `/user/${id}/copyfolio`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goUserLeaderfolio = (id?: string) => {
        const url = `/user/${id}/leaderfolio`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goUserCopyfolioHistory = (id?: string) => {
        const url = `/user/${id}/copyfolio/history`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }
        router?.push(url)
    }

    const goDownload = () => {
        router?.push(`/download`)
    }

    const goKeyHistory = (keyId?: string) => {
        router?.push(`/key/${keyId}/history`)
    }

    const goLeaderKeyHistory = (keyId?: string) => {
        router?.push(`/key/${keyId}/leaderfolio/history`)
    }

    const goLeaderEarning = (keyId?: string) => {
        router?.push(`/key/${keyId}/leaderfolio/pnl`)
    }

    const goWallet = () => {
        const url = `/account/wallet`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goWalletHistory = () => {
        const url = `/account/wallet/history`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goCopierList = (id?: string) => {
        const url = `/key/${id}/leaderfolio/copiers`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goKeyOpenOrder = (id?: string) => {
        const url = `/key/${id}/open-order`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goOpenOrder = (id?: string) => {
        const url = `/key/${id}/leaderfolio/orders`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }
        router?.push(url)
    }

    const goAccountInvoice = () => {
        const url = `/account/invoice`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goLeaderboard = (tab?: 'main' | 'copyleader' | 'league' | 'league-all') => {
        router?.push({pathname: '/leaderboard', query: {tab: tab}}, undefined, {shallow: true})
    }

    const goLeaderboardLeague = (leagueId?: string) => {
        router?.push({pathname: '/leaderboard', query: {tab: 'league', league: leagueId}}, undefined, {shallow: true})
    }

    const goTermsAndConditions = (isNewWindow?: boolean) => {
        if (isNewWindow) {
            window.open(`/${router?.locale}/terms/terms-and-conditions`, '_blank', 'noopener, noreferrer')
        } else {
            router?.push({pathname: '/terms/terms-and-conditions'})
        }
    }

    const goPrivacyPolicy = (isNewWindow?: boolean) => {
        if (isNewWindow) {
            window.open(`/${router?.locale}/terms/privacy`, '_blank', 'noopener, noreferrer')
        } else {
            router?.push({pathname: '/terms/privacy'})
        }
    }

    const goSetting = () => {
        const url = `/account/setting`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goApi = () => {
        const url = `/account/api`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goCreateApi = (exchange?: string) => {
        const url = `/account/api/create${exchange ? `?exchange=${exchange}` : ''}`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goReferral = () => {
        const url = '/account/referral'

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }
    const goReferralHistory = () => {
        const url = `/account/referral/history`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push(url)
    }

    const goReferralRule = () => {
        router?.push('/account/referral/rule')
    }

    const goSubscriber = (tab: SubscribeType = 'following') => {
        const url = '/account/subscriber'

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        router?.push({pathname: url, query: {tab: tab}})
    }

    const goKeyDetail = (id: string, period?: 'all' | 'copy' | 'leader') => {
        const query = () => {
            if (period === 'all') {
                return 'all'
            }
            if (period === 'copy') {
                return 'copy'
            }
            if (period === 'leader') {
                return 'leader'
            }
        }
        const url = `/key/${id}/portfolio`

        if (isNotLogin()) {
            redirectLogin(url)
            return
        }

        if (query()) {
            router?.push({pathname: url, query: {period: query()}})
        } else {
            router?.push({pathname: url})
        }
    }

    const goLeaderDetail = (id: string) => {
        const url = `/key/${id}/portfolio`

        router?.push({pathname: url, query: {period: 'leader'}})
    }

    const goNotification = () => {
        const url = `/account/notification`
        if (isNotLogin()) {
            redirectLogin(url)
            return
        }
        router?.push(url)
    }
    const goAccountProPayment = () => {
        const url = `/account/pro/payment`
        router?.push(url)
    }
    const goAccountPro = () => {
        const url = `/account/pro`
        if (isNotLogin()) {
            redirectLogin(url)
            return
        }
        router?.push(url)
    }

    const goProLanding = () => {
        const url = `/landing/pro`
        router?.push(url)
    }

    const goPoint = () => {
        const url = `/account/point`
        router?.push(url)
    }

    const goAnnouncements = (tab?: AnnounceType) => {
        const url = `/announce`
        if (!tab) {
            router?.push(url)
            return
        }
        router?.push({pathname: url, query: {tab: tab}}, undefined, {shallow: true})
    }
    const goAnnouncementDetail = (id?: string, category?: string, page?: number) => {
        if (category && page <= 1) {
            router?.push(`/announce/${id}?category=${category}`)
            return
        }
        if (page > 1 && !category) {
            router?.push(`/announce/${id}?page=${page}`)
            return
        }
        if (category && page > 1) {
            router?.push(`/announce/${id}?category=${category}&page=${page}`)
            return
        }
        router?.push(`/announce/${id}`)
    }

    const goLeagueDetail = (path: string) => {
        router?.push(`/${path}`)
    }

    const goEventPage = (path: string) => {
        router?.push(`/event/${path}`)
    }

    return {
        redirectLoginWhenNotLogin,
        goUserPortfolio,
        goUserCopyfolio,
        goUserLeaderfolio,
        goKeyDetail,
        goLeaderDetail,
        goHome,
        goDownload,
        goKeyHistory,
        goWallet,
        goWalletHistory,
        goCopierList,
        goOpenOrder,
        goKeyOpenOrder,
        goAccountInvoice,
        goLeaderboard,
        goLeaderboardLeague,
        goUserCopyfolioHistory,
        goTermsAndConditions,
        goPrivacyPolicy,
        goSetting,
        goApi,
        goCreateApi,
        goReferral,
        goReferralHistory,
        goReferralRule,
        goSubscriber,
        goNotification,
        goPost,
        goAccountProPayment,
        goProLanding,
        goPoint,
        goAccountPro,
        goLeaderKeyHistory,
        goLeaderEarning,
        goAnnouncements,
        goAnnouncementDetail,
        goLeagueDetail,
        goEventPage,
    }
}

export default useNavigation
