import React from 'react'
import TagText from '@component/Text/TagText'
import {toast, ToastOptions} from 'react-toastify'

interface IProps {
    text: string
    className?: string
}

export const showToast = (text: string, options?: ToastOptions) => {
    return toast(<WizToastMessage text={text} />, options)
}

const WizToastMessage: React.FC<IProps> = ({text, className}) => {
    return (
        <div
            className={`bg-dark_gray07 dark:bg-dark_gray01 shadow-normal text-center text-white dark:text-bg_dark_gnb text-body1 px-[20px] py-[15px] rounded-[5px] flex items-center gap-x-[20px] sm:mx-[10px] ${className}`}>
            <TagText className={'text-dark_gray01 dark:text-gray01 flex-1'}>{text ?? ''}</TagText>
        </div>
    )
}

export default React.memo(WizToastMessage)
