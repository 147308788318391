import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {IConfirmResponse} from '@api/auths/register/Register'
import {safeReturn} from '@util/etc'

export const apiEditName = async (nickname?: string): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost('edit', {
        name: safeReturn(nickname),
    })
}
