import {ApiRes} from "@type/api";
import {apiGet, apiGetMeme} from "@api/api";

export interface IFloatingMessage {
    action_url?: {app?: string, web?: string},
    button_text?: {en?: string, ko?: string},
    button_url?: {app_ko?: string,app_en?: string, web_ko?: string, web_en?: string},
    emoji?: string,
    end_at?: number,
    filter?: any,
    id: string,
    language?: string,
    priority?: number,
    start_at?: number,
    text?: {en: string, ko: string},
}

export const apiGetFloatingMessage = async (): Promise<ApiRes<IFloatingMessage[]>> => {
    return await apiGet(`floating/messages`)
}
