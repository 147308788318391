import BigNumber from 'bignumber.js'
import {ENGLISH_SUFFICE, KOREAN_SUFFICE} from '@constant/number'
import {ICurrency} from '@type/currency'
import {useRouter} from 'next/router'
import {useCurrencyStore} from '@store/CurrencyStore'
import {shallow} from 'zustand/shallow'
import {FormatCurrencyNumberOptions} from '@util/numbers'

interface CurrencyDisplayOption {
    priceDecimalPlace: {
        small: number
        default: number
    }
    balanceDecimalPlace: {
        small: number
        default: number
    }
    circleBalanceDecimalPlace: {
        default: number
    }
}

export interface IDisplayBalanceOptions {
    showPostSymbol?: boolean
    showPreSign?: boolean
    isUSD?: boolean
    isApproximately?: boolean
    isAbsolute?: boolean
    isShowMinusSymbol?: boolean
    isDigit?: boolean
    isShowFullNumber?: boolean
    isInvisibleUnicode?: boolean
}

export interface IDisplayPriceOptions {
    showPostSymbol?: boolean
    showPreSign?: boolean
    currencySymbol?: string
    isApproximately?: boolean
    isShowMinusSymbol?: boolean
    isInvisibleUnicode?: boolean
}

export type CurrencyFormat = {
    currency: ICurrency
    numberText: string
    isApproximate?: boolean
    isMinus?: boolean
    isInvisibleUnicode?: boolean
}

export const DEFAULT_CURRENCY: ICurrency = {
    symbol: 'USD',
    value: 1,
    is_stable: true,
    name: 'US Dollar',
    unicode: '$',
    is_fiat: true,
    price_decimals: {
        small: 8,
        default: 2,
    },
    balance_decimals: {
        small: 2,
        default: 2,
    },
    circle_decimals: {
        small: 0,
        default: 1,
    },
}

// calcPrice
const convertDollarsToCurrency = (dollarValue?: number | BigNumber | string, currencyValue?: number): number => {
    if (!dollarValue || !currencyValue) {
        return 0
    } else {
        return new BigNumber(dollarValue).div(currencyValue).toNumber()
    }
}

// formatKM
const getShorthandFormat = (
    bigNumber: BigNumber,
    placeNumber: number = 1,
    isApproximate: boolean,
    languageCode,
): string => {
    const scaleLetter = languageCode == 'ko' ? KOREAN_SUFFICE : ENGLISH_SUFFICE
    const scalePlace = languageCode == 'ko' ? 4 : 3
    const scaleIndex = Math.floor((bigNumber?.abs().e || 0) / scalePlace)

    const suffix = scaleLetter[scaleIndex] ? scaleLetter[scaleIndex] : ''
    const unitDivisionValue = Math.pow(10, scaleIndex * scalePlace)
    const formatted = bigNumber?.dividedBy(unitDivisionValue)

    const isShorten = scaleIndex !== 0
    const decimalPlace = isShorten && !isApproximate ? 1 : placeNumber

    return formatted.toFormat(decimalPlace) + suffix
}

//formatCurrencyNumber
const getCurrencyFormat = (options: FormatCurrencyNumberOptions): string => {
    const {
        number, // 숫자 텍스트
        currencyUnicode, //현재 통화 유니코드 ($...)
        showPostSymbol, // number 뒤에 붙는 Symbol 여부
        currencySymbol = options.currencySymbol ?? 'USD', // number 뒤에 붙는 Symbol (USD, KRW...)
        isPrice = false, // Price인지, Balance인지
        isAbsolute = false, // 절대값으로 산출
        isApproximately = false, // 근사값으로 산출
        isShowMinusSymbol = false, // - 표기 여부
        isInvisibleUnicode = false, // 유니코드 감춤 여부
    } = options
    const textApproximately = isApproximately ? '≈' + ' ' : ''
    const signMinusSymbol = !isAbsolute && isShowMinusSymbol ? '-' : ''
    const uniCode = isInvisibleUnicode ? '' : currencyUnicode

    const postText = showPostSymbol ? ` ${currencySymbol?.toUpperCase()}` : ''

    return `${textApproximately}${signMinusSymbol}${uniCode}${number}${postText}`
}

// formatCurrencyNumber  > if isApproximately
const getApproximation = (value: BigNumber): BigNumber => {
    const divider = Math.pow(10, Math.floor(value?.e || 0))
    return value?.dividedToIntegerBy(divider).multipliedBy(divider)
}

const getPriceDecimalPlace = (currency: ICurrency, absoluteValue: BigNumber): number => {
    if (absoluteValue?.isGreaterThanOrEqualTo(1e9)) {
        return 0
    }

    if (currency?.symbol.toUpperCase() === 'BTC') {
        return absoluteValue?.isLessThan(1) ? currency?.price_decimals.small : currency?.price_decimals.default
    } else if (currency?.symbol.toUpperCase() === 'KRW') {
        // 통화가 KRW인 경우 최소 첫 번째 자리수의 천분의 일까지 보이도록 한다.
        if (absoluteValue?.isGreaterThanOrEqualTo(1e3)) {
            // 10^3보다 큰 수는 소수점을 표기할 필요가 없다.
            return currency?.price_decimals.default
        } else {
            // 현재 지수에서 3을 뺀 자릿수까지 표시한다.
            const currentExponent = absoluteValue?.e ?? 0
            const EXPONENT_OFFSET = 3
            const ruleBasedDecimalPlace = Math.abs(currentExponent - EXPONENT_OFFSET)
            const currentDecimalPlace = absoluteValue?.decimalPlaces() ?? 0

            // 소수점 뒤의 불필요한 0 표기를 방지하기 위해
            // 현재 숫자의 소수점 자리수(currentDecimalPlace)와 규칙에서 얻은 소수점 자리수(decimalPlace) 중 작은 값을 반환한다.
            return Math.min(ruleBasedDecimalPlace, currentDecimalPlace)
        }
    } else {
        if (absoluteValue?.isLessThan(1)) {
            const ruleBasedDecimalPlace = currency?.price_decimals.small
            const currentDecimalPlace = absoluteValue?.decimalPlaces() ?? 0

            return Math.min(ruleBasedDecimalPlace, currentDecimalPlace)
        } else {
            return currency?.price_decimals.default
        }
    }
}

const getBalanceDecimalPlace = (currency: ICurrency, absoluteValue: BigNumber, isDigit?: boolean): number => {
    if (absoluteValue?.isGreaterThanOrEqualTo(1e9)) {
        return 0
    }
    if (isDigit === false) {
        return 0
    } else {
        return absoluteValue?.isLessThan(1) ? currency?.balance_decimals.small : currency?.balance_decimals.default
    }
}

const getApproximateBalanceDecimalPlace = (
    currency: ICurrency,
    absoluteValue: BigNumber,
    isShorthand: boolean,
): number => {
    if (currency?.symbol.toUpperCase() === 'BTC') {
        return absoluteValue?.decimalPlaces() ?? 0
    } else {
        if (absoluteValue?.isLessThan(1)) {
            const ruleBasedDecimalPlace = isShorthand
                ? currency?.circle_decimals.small
                : currency?.balance_decimals.small
            const currentDecimalPlace = absoluteValue?.decimalPlaces() ?? 0

            if (currentDecimalPlace <= ruleBasedDecimalPlace) {
                return currentDecimalPlace
            }
        }
        return 0
    }
}

const useFormatNumber = () => {
    const language = useRouter().locale
    const {selectedSymbol, getSelectedCurrency, getCurrency, currencies, getUSDCurrency} = useCurrencyStore(
        state => ({
            selectedSymbol: state.selectedSymbol,
            getSelectedCurrency: state.getSelectedCurrency,
            getCurrency: state.getCurrency,
            currencies: state.currencies,
            getUSDCurrency: state.getUSDCurrency,
        }),
        shallow,
    )
    const currency = getSelectedCurrency()
    const usdCurrency = getUSDCurrency()
    const displayPrice = (price?: number | BigNumber, options?: IDisplayPriceOptions): string => {
        // 통화에 맞춰 현재 가격을 계산
        const currencyValue = convertDollarsToCurrency(price, currency?.value)

        // BigNumber 생성
        const bigNumberValue = new BigNumber(currencyValue)
        const currencyUnicode = currency?.unicode
        const isMinus = bigNumberValue?.isNegative()

        // Nan 체크
        if (bigNumberValue?.isNaN()) {
            return getCurrencyFormat({
                number: '0',
                currencySymbol: options?.currencySymbol ?? selectedSymbol,
                isPrice: true,
                currencyUnicode: options?.showPreSign ? currencyUnicode : '',
                showPostSymbol: options?.showPostSymbol,
                isApproximately: options?.isApproximately,
                isShowMinusSymbol: false,
                isInvisibleUnicode: options?.isInvisibleUnicode,
            })
        }

        // 절대값 체크
        const absoluteValue = bigNumberValue?.abs()

        // decimalPlace 구한 뒤 정수로 변경
        let decimalPlace = getPriceDecimalPlace(currency, absoluteValue)
        if (!Number.isInteger(decimalPlace) || decimalPlace < 0) {
            decimalPlace = 2 // NaN일 때 등 fallback
        }

        // 문자열 출력
        const numberText = absoluteValue?.toFormat(decimalPlace)

        return getCurrencyFormat({
            number: numberText,
            currencySymbol: options?.currencySymbol ? options?.currencySymbol : selectedSymbol,
            isPrice: true,
            currencyUnicode: options?.showPreSign ? currencyUnicode : '',
            showPostSymbol: options?.showPostSymbol,
            isApproximately: options?.isApproximately,
            isShowMinusSymbol: isMinus,
            isInvisibleUnicode: options?.isInvisibleUnicode,
        })
    }

    const displayBalance = (balance?: number | BigNumber | string, options?: IDisplayBalanceOptions): string => {
        const isUSD = options?.isUSD

        const currencyValue = convertDollarsToCurrency(balance, isUSD ? usdCurrency?.value : currency?.value)
        const bigNumberValue = new BigNumber(currencyValue)
        const isMinus = bigNumberValue?.isNegative()
        const absoluteValue = bigNumberValue?.abs()
        const decimalPlace = getBalanceDecimalPlace(isUSD ? usdCurrency : currency, absoluteValue, options?.isDigit)
        const numberText = absoluteValue?.toFormat(decimalPlace)

        const getUnicode = () => {
            if (isUSD) {
                return usdCurrency?.unicode
            } else {
                return getSelectedCurrency()?.unicode
            }
        }

        return getCurrencyFormat({
            number: numberText,
            currencySymbol: isUSD ? 'USD' : getSelectedCurrency()?.symbol,
            isPrice: false,
            // isShortForm: options?.isShortForm,
            currencyUnicode: options?.showPreSign ? (getUnicode() ? getUnicode() : '$') : '',
            showPostSymbol: options?.showPostSymbol,
            isApproximately: options?.isApproximately,
            isAbsolute: options?.isAbsolute,
            isDigit: options?.isDigit,
            isShowFullNumber: options?.isShowFullNumber,
            isShowMinusSymbol: isMinus ? options?.isShowMinusSymbol ?? true : false,
            isInvisibleUnicode: options?.isInvisibleUnicode,
        })
    }

    const displayApproximateBalance = (
        balance?: number | BigNumber | string,
        options?: IDisplayBalanceOptions,
    ): string => {
        const isUSD = options?.isUSD

        const currencyValue = convertDollarsToCurrency(balance, isUSD ? usdCurrency?.value : currency?.value)
        const bigNumberValue = new BigNumber(currencyValue)

        const isMinus = bigNumberValue?.isNegative()
        const absoluteValue = getApproximation(bigNumberValue?.abs())

        const decimalPlace = getApproximateBalanceDecimalPlace(currency, absoluteValue, false)
        const numberText = absoluteValue?.toFormat(decimalPlace)

        const getUnicode = () => {
            if (isUSD) {
                return usdCurrency?.unicode
            } else {
                return getSelectedCurrency()?.unicode
            }
        }

        return getCurrencyFormat({
            number: numberText,
            currencySymbol: getSelectedCurrency()?.symbol,
            isPrice: false,
            // isShortForm: options?.isShortForm,
            currencyUnicode: options?.showPreSign ? getUnicode() : '',
            showPostSymbol: options?.showPostSymbol,
            isApproximately: true,
            isAbsolute: options?.isAbsolute,
            isDigit: options?.isDigit,
            isShowFullNumber: options?.isShowFullNumber,
            isShowMinusSymbol: isMinus ? options?.isShowMinusSymbol ?? true : false,
            isInvisibleUnicode: options?.isInvisibleUnicode,
        })
    }

    const displayBalanceShort = (balance?: number | BigNumber | string, options?: IDisplayBalanceOptions): string => {
        const isUSD = options?.isUSD
        const currencyValue = convertDollarsToCurrency(balance, isUSD ? usdCurrency?.value : currency?.value)
        const bigNumberValue = new BigNumber(currencyValue)

        const isMinus = bigNumberValue?.isNegative()
        const absoluteValue = bigNumberValue?.abs()

        let numberText
        if (currency?.symbol.toUpperCase() === 'BTC') {
            const decimalPlace = currency?.circle_decimals.default
            numberText = absoluteValue?.toFormat(decimalPlace)
        } else {
            if (absoluteValue?.isLessThan(1)) {
                const decimalPlace = currency?.circle_decimals.small
                numberText = absoluteValue?.toFormat(decimalPlace)
            } else {
                const defaultDecimalPlace = currency?.balance_decimals.default
                const circleDecimalPlace = currency?.circle_decimals.default
                numberText = getShorthandFormat(
                    absoluteValue,
                    Math.min(defaultDecimalPlace, circleDecimalPlace),
                    false,
                    language,
                )
            }
        }

        const getUnicode = () => {
            if (isUSD) {
                return usdCurrency?.unicode
            } else {
                return getSelectedCurrency()?.unicode
            }
        }

        return getCurrencyFormat({
            number: numberText,
            currencySymbol: getSelectedCurrency()?.symbol,
            isPrice: false,
            // isShortForm: options?.isShortForm,
            currencyUnicode: options?.showPreSign ? getUnicode() : '',
            showPostSymbol: options?.showPostSymbol,
            isApproximately: false,
            isAbsolute: options?.isAbsolute,
            isDigit: options?.isDigit,
            isShowFullNumber: options?.isShowFullNumber,
            isShowMinusSymbol: isMinus ? options?.isShowMinusSymbol ?? true : false,
            isInvisibleUnicode: options?.isInvisibleUnicode,
        })
    }

    const displayApproximateBalanceShort = (
        balance?: number | BigNumber | string,
        options?: IDisplayBalanceOptions,
    ): string => {
        const isUSD = options?.isUSD
        const currencyValue = convertDollarsToCurrency(balance, isUSD ? usdCurrency?.value : currency?.value)
        const bigNumberValue = new BigNumber(currencyValue)

        const isMinus = bigNumberValue?.isNegative()
        const absoluteValue = getApproximation(bigNumberValue?.abs())

        const getUnicode = () => {
            if (isUSD) {
                return usdCurrency?.unicode
            } else {
                return getSelectedCurrency()?.unicode
            }
        }

        let numberText
        if (currency?.symbol.toUpperCase() !== 'BTC' && absoluteValue?.isGreaterThanOrEqualTo(1)) {
            const decimalPlace = 0
            numberText = getShorthandFormat(absoluteValue, decimalPlace, true, language)
        } else {
            const decimalPlace = getApproximateBalanceDecimalPlace(currency, absoluteValue, true)
            numberText = absoluteValue?.toFormat(decimalPlace)
        }

        return getCurrencyFormat({
            number: numberText,
            currencySymbol: getSelectedCurrency()?.symbol,
            isPrice: false,
            // isShortForm: options?.isShortForm,
            currencyUnicode: options?.showPreSign ? getUnicode() : '',
            showPostSymbol: options?.showPostSymbol,
            isApproximately: true,
            isAbsolute: options?.isAbsolute,
            isDigit: options?.isDigit,
            isShowFullNumber: options?.isShowFullNumber,
            isShowMinusSymbol: isMinus ? options?.isShowMinusSymbol ?? true : false,
            isInvisibleUnicode: options?.isInvisibleUnicode,
        })
    }

    const convertToBase = (n: number | string | BigNumber, currencySymbol: string): string => {
        let base = ''
        const c = currencies.find(e => e.symbol.toUpperCase() === currencySymbol.toUpperCase())
        if (c && c.value !== 0) {
            return new BigNumber(n).times(c.value).toString()
        }
        return base
    }

    const displayUSDBalance = (dollarValue?: number | BigNumber | string, options?: IDisplayBalanceOptions) => {
        const c = DEFAULT_CURRENCY
        const currencyValue = convertDollarsToCurrency(dollarValue, c.value)
        const bigNumberValue = new BigNumber(currencyValue)

        const isMinus = bigNumberValue?.isNegative()
        const absoluteValue = bigNumberValue?.abs()

        const decimalPlace = getBalanceDecimalPlace(c, absoluteValue)
        const numberText = absoluteValue?.toFormat(decimalPlace)

        return getCurrencyFormat({
            number: numberText,
            currencySymbol: getCurrency('USD')?.symbol,
            isPrice: false,
            // isShortForm: options?.isShortForm,
            currencyUnicode: options?.showPreSign ? getCurrency('USD')?.unicode : '',
            showPostSymbol: options?.showPostSymbol,
            isApproximately: options?.isApproximately,
            isAbsolute: options?.isAbsolute,
            isDigit: options?.isDigit,
            isShowFullNumber: options?.isShowFullNumber,
            isShowMinusSymbol: isMinus ? options?.isShowMinusSymbol ?? true : false,
            isInvisibleUnicode: options?.isInvisibleUnicode,
        })
    }

    const displayPercent = (profit?: number | BigNumber | string, digit: number = 2): string => {
        if (!profit && digit === 0) {
            return '0%'
        }
        if (!profit) {
            return '0.00%'
        }

        const n = new BigNumber(profit)
        //For amount if it's a big number no one really cares about decimal points.

        return `${n.toFormat(digit)}%`
    }

    const displayLeverage = (price?: number | BigNumber | string): string => {
        if (!price) {
            return '0'
        }
        const n = new BigNumber(price)
        if (n.isPositive() && n.isLessThanOrEqualTo(0.01)) {
            return n.toPrecision(3)
        }
        //For amount if it's a big number no one really cares about decimal points.
        if (n.abs().isGreaterThan(10000)) {
            return n.toFormat(0)
        }
        return n.decimalPlaces(2, 1).toFormat()
    }

    const displayNumber = (number: number | BigNumber | string, digit?: number): string => {
        if (!number) return '0'

        return new BigNumber(number).toFormat(digit)
    }

    const displayAmount = (amount: number | BigNumber | string, price: number = 1000000): string => {
        const bigNumberPrice = new BigNumber(price)
        const bigNumberAmount = new BigNumber(amount)

        // price가 10의 몇 제곱인지 ex)1,000,000 라면 currentExponent = 6
        const currentExponent = bigNumberPrice.e ?? 0

        // currentExponent에 따른 decimalPlaces 결정
        const decimalPlaces = [2, 3, 4, 8].find((_, index) => currentExponent < index + 2) ?? 8

        return Number(amount) < 0
            ? `(${bigNumberAmount?.abs()?.toFormat(decimalPlaces, BigNumber?.ROUND_DOWN)})`
            : bigNumberAmount.toFormat(decimalPlaces, BigNumber?.ROUND_DOWN)
    }

    return {
        displayPrice,
        displayBalance,
        displayBalanceShort,
        displayApproximateBalance,
        displayApproximateBalanceShort,
        displayUSDBalance,
        displayNumber,
        displayLeverage,
        displayPercent,
        displayAmount,
    }
}

export default useFormatNumber
