import React, {useMemo} from 'react'
import WizImage from '@component/images/WizImage'
import ic_default_user from '@image/ic_default_user.webp'
import ic_default_user_dark from '@image/ic_default_user_dark.webp'
import useDark from '@hook/useDark'
import {DarkModeStore} from '@store/DarkmodeStore'

interface IProps {
    src: any
    imageClassName?: string
    containerClassName?: string
    size?: number
    isPro?: boolean
    unoptimized?: boolean
}

const UserThumbnail: React.FC<IProps> = ({
    src,
    imageClassName,
    containerClassName,
    size,
    isPro = false,
    unoptimized,
}) => {
    const {isRenderDark} = useDark()
    const {isCheckDark} = DarkModeStore()
    const imageSize = useMemo(() => {
        if (isPro) {
            if (size > 50) return size - 8
            else return size - 4
        } else return size
    }, [size, isPro])

    const proContainerClassName = useMemo(() => {
        if (isPro) {
            if (size > 50) return 'border border-[2px] p-[2px] border-font_pro'
            else return 'border border-[1px] p-[1px] border-font_pro'
        } else return ''
    }, [isPro, size])
    return (
        <div className={`rounded-full flex-none ${proContainerClassName} ${containerClassName}`}>
            <WizImage
                src={src}
                containerClassName={`flex-none`}
                imageClassName={`${imageClassName} rounded-full`}
                width={imageSize}
                height={imageSize}
                placeHolder={isRenderDark && isCheckDark ? ic_default_user_dark : ic_default_user}
                unoptimized={unoptimized}
            />
        </div>
    )
}

export default React.memo(UserThumbnail)
