import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetDailyReport, IDailyReport} from '@api/user/report/getDailyReport'
import {useRouter} from 'next/router'

const useQueryGetDailyReport = (id?: string, userId?: string) => {
    const router = useRouter()
    return useQuery({
        queryKey: QueryCacheKeys.report.getDailyReport(id, userId, router?.locale),
        enabled: id !== undefined || id !== '',
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const {data} = await apiGetDailyReport(id)
            if (data) return data
            return {} as IDailyReport
        },
    })
}

export default useQueryGetDailyReport
