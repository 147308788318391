import React, {useMemo} from 'react'
import useDark from '@hook/useDark'

interface Props {
    className?: string
    visible: boolean
    size?: 'smallest' | 'small' | 'large'
    customSize?: number
    color?: string
    backgroundClassName?: string
}

const Spinner = ({className, color, visible, size, customSize, backgroundClassName}: Props) => {
    const {isRenderDark} = useDark()
    const fill = color ? color : isRenderDark ? '#27C89B' : '#0AAF82'

    const spinnerSize = useMemo(() => {
        if (customSize) return customSize
        switch (size) {
            case 'large':
                return 36
            case 'small':
                return 24
            case 'smallest':
                return 14
            default:
                return 24
        }
    }, [size, customSize])

    if (!visible) return null
    return (
        <div
            style={{width: spinnerSize, height: spinnerSize}}
            className={`${className} animate-[spinner_1s_linear_infinite]`}>
            <svg width={spinnerSize} height={spinnerSize} viewBox={'0 0 36 36'}>
                <circle
                    style={{strokeLinecap: 'round', stroke: fill}}
                    className={`w-full fill-none stroke-[6px] opacity-[0.22] ${backgroundClassName}`}
                    cx={'50%'}
                    cy={'50%'}
                    r={'40%'}></circle>
                <circle
                    style={{strokeLinecap: 'round', stroke: fill, strokeDashoffset: '130px', strokeDasharray: '100px'}}
                    className={'w-full fill-none stroke-[6px]'}
                    cx={'50%'}
                    cy={'50%'}
                    r={'40%'}></circle>
            </svg>
        </div>
    )
}

export default React.memo(Spinner)
