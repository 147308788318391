import React, {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import TagText from '@component/Text/TagText'
import {LoginContext} from '@feature/common/components/LoginModal/routes/LoginRoutes/LoginRoutes'
import {isEmpty} from '@util/strings'
import {apiLoginEmail} from '@api/auths/login/login'
import useSnackbar from '@hook/useSnackbar'
import {useLoginStore} from '@store/LoginStore'
import {apiSendLoginCode} from '@api/auths/login/sendCode'
import useLoginModal from '@feature/common/components/LoginModal/hooks/useLoginModal'
import {createLog} from '@util/logs'
import {useRouter} from 'next/router'

interface IProps extends ILoginModalProps {}

const ScreenLoginWithPassword: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const router = useRouter()
    const navigate = useNavigate()
    const {email, password, setPassword} = useContext(LoginContext)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingSendEmailCode, setIsLoadingSendEmailCode] = useState(false)
    const {showErrorSnackbar} = useSnackbar()
    const login = useLoginStore(state => state.login)
    const {redirectAfterLogin} = useLoginModal()

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const onClickSubmit = async () => {
        createLog('event', 'login_email_password_submit')

        setIsLoading(true)
        const {data, status, error} = await apiLoginEmail(email, password)
        if (data) {
            login(data?.user_id, data?.access_token)
            closeModal()
            redirectAfterLogin()
        } else {
            if (status === 5011) {
                createLog('error', 'login_email_password_submit_failed_incorrect_password')
                setInputState(InputState.ERROR)
                setInputErrorMessage(t('loginModal.loginWithPassword.submit.res.fail.incorrectPassword'))
            } else {
                createLog('error', 'login_email_password_submit_failed')
                showErrorSnackbar(error)
            }
        }
        setIsLoading(false)
    }

    const onClickLoginWithCode = async () => {
        createLog('event', 'login_email_password_code_submit')
        setIsLoadingSendEmailCode(true)
        await apiSendLoginCode(email)
        navigate('/login_code')
        setIsLoadingSendEmailCode(false)
    }
    const onClickFindPassword = () => {
        createLog('event', 'login_email_password_find_password')
        navigate('/find_password')
    }

    return (
        <LoginModalLayout closeModal={closeModal} title={t('loginModal.loginWithPassword.page.title')}>
            <TagText className={'text-body1 text-gray02 dark:text-dark_gray02 break-words'}>
                {t('loginModal.loginWithPassword.desc', {
                    email: email.length > 30 ? email.slice(0, 30) + '...' : email,
                })}
            </TagText>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.loginWithPassword.password.label')}
            </Text>
            <WizInputText className={'hidden'} text={email} autoComplete={'username'} />
            <WizInputText
                className={'mt-[5px]'}
                text={password}
                type={'password'}
                state={inputState}
                autoFocus={true}
                autoComplete={'current_password'}
                errorDescription={inputErrorMessage}
                onChanged={text => {
                    setPassword(text)
                    setInputState(InputState.DEFAULT)
                }}
                placeholder={t('loginModal.loginWithPassword.password.input.placeholder')}
                onPressEnter={onClickSubmit}
                dataCy={'input_password'}
            />
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.loginWithPassword.submit.continue.text')}
                className={'mt-[10px] w-full'}
                disabled={isEmpty(password)}
                isLoading={isLoading}
                onClick={onClickSubmit}
                dataCy={'btn_continue'}
            />
            <Text
                className={
                    'text-body1 text-gray02 dark:text-dark_gray02 text-center mt-[10px] underline cursor-pointer'
                }
                onClick={onClickFindPassword}>
                {t('loginModal.loginWithPassword.forgotPassword.text')}
            </Text>
            <div className={'flex w-full relative py-[10px] mt-[10px]'}>
                <div className={'flex-1 border-gray07 dark:border-dark_gray07  border-t mt-[12px]'} />
                <Text className={'text-body1 text-gray03 dark:text-dark_gray03 text-center px-[10px]'}>
                    {t('loginModal.loginWithPassword.or.text')}
                </Text>
                <div className={'flex-1 border-gray07 dark:border-dark_gray07 border-t mt-[12px]'} />
            </div>

            <WizButton
                buttonType={'gray'}
                className={'mt-[10px] w-full'}
                textClassName={'font-normal'}
                isLoading={isLoadingSendEmailCode}
                text={t('loginModal.loginWithPassword.signInWithCode.text')}
                onClick={onClickLoginWithCode}
            />
            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[10px] text-center'}>
                {t('loginModal.loginWithPassword.signInWithCode.desc')}
            </Text>
        </LoginModalLayout>
    )
}

export default ScreenLoginWithPassword
