import React, {useEffect, useMemo} from 'react'
import Footer from '@component/footer/Footer'
import dynamic from 'next/dynamic'
import useNavigation from '@hook/useNavigation'
import {TipContext} from '@component/tooltip/TipContext'
import Compose from '@util/compose'
import usePageTipHandler from '@component/layouts/PageConfig/hooks/usePageTipHandler'
import useDark from '@hook/useDark'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import HeadContainer from '@component/header/Head/HeadContainer'
import {ImportantAlertsProvider} from '@hook/useImportantAlerts'

export interface IMetaTags {
    title?: string
    description?: string
    image?: string
}

interface IProps {
    enableFloatingNavigation?: boolean
    navTheme?: 'light' | 'dark'
    navLogoType?: 'default' | 'white'
    footerTheme?: 'light' | 'dark'
    requireLogin?: boolean
    metaTags?: IMetaTags
    hideLoginButtons?: boolean
    children?: any
    navClassName?: string
    bodyClassName?: string
    mainClassName?: string
    footerClassName?: string
    scrolledNavColor?: string
    pageName?: string
    onClickBack?: () => void
}

const Navigation = dynamic(import('@component/header/GlobalNavigation/GlobalNavigation'), {
    ssr: false,
})

const PageConfig: React.FC<IProps> = ({
    enableFloatingNavigation = false,
    navTheme = 'light',
    navLogoType,
    footerTheme = 'light',
    metaTags = {},
    hideLoginButtons = false,
    requireLogin = false,
    navClassName,
    bodyClassName = '',
    mainClassName = '',
    footerClassName,
    scrolledNavColor,
    pageName,
    onClickBack = undefined,
    children,
}) => {
    const {redirectLoginWhenNotLogin} = useNavigation()
    const {isRenderDark} = useDark()
    const {registerTip, tipList, nextOpenTip, popTip} = usePageTipHandler()

    const {} = useQueryFetchMe()

    useEffect(() => {
        if (requireLogin) redirectLoginWhenNotLogin()
    }, [requireLogin, redirectLoginWhenNotLogin])

    const isRenderNavTheme = useMemo(() => {
        if (!enableFloatingNavigation) {
            if (isRenderDark) {
                return 'dark'
            } else {
                return 'light'
            }
        } else {
            return navTheme
        }
    }, [isRenderDark, navTheme, enableFloatingNavigation])

    return (
        <>
            <HeadContainer metaTags={metaTags} />
            <ImportantAlertsProvider>
                <div className={`flex flex-col min-h-screen relative ${bodyClassName}`}>
                    <Navigation
                        className={`w-full ${enableFloatingNavigation ? 'fixed' : 'sticky top-0'} ${navClassName}`}
                        navTheme={isRenderNavTheme}
                        navLogoType={navLogoType}
                        scrolledNavColor={scrolledNavColor}
                        hideLoginButtons={hideLoginButtons}
                        enableFloatingNavigation={enableFloatingNavigation}
                    />
                    <Compose items={[[TipContext, {value: {registerTip, tipList, nextOpenTip, popTip}}]]}>
                        <main className={`grow flex`}>
                            <div className={`grow ${mainClassName}`}>{children}</div>
                        </main>
                    </Compose>
                    <Footer theme={footerTheme} className={footerClassName} />
                </div>
            </ImportantAlertsProvider>
        </>
    )
}

export default PageConfig
