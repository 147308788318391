import {create} from 'zustand'

interface ILoginModalStore {
    isVisibleLoginModal?: boolean
    initRoute?: string
    redirectUrl?: string
    code?: string
    setRedirectUrl: (redirectUrl?: string) => void
    setInitRoute: (initRoute?: string) => void
    showLoginModal: (redirectUrl?: string, code?: string) => void
    showLoginModalWithInitRoute: (initRoute: string, redirectUrl?: string) => void
    hideLoginModal: () => void
}

export const useLoginModalStore = create<ILoginModalStore>()((set, get) => ({
    isVisibleLoginModal: false,
    setRedirectUrl: (redirectUrl?: string) => {
        set(state => ({redirectUrl: redirectUrl}))
    },
    setInitRoute: (initRoute?: string) => {
        set(state => ({initRoute: initRoute}))
    },
    showLoginModal: (redirectUrl?: string, code?: string) => {
        set(state => ({isVisibleLoginModal: true, redirectUrl: redirectUrl, code: code}))
    },
    showLoginModalWithInitRoute: (initRoute: string, redirectUrl?: string) => {
        set(state => ({isVisibleLoginModal: true, initRoute: initRoute, redirectUrl: redirectUrl}))
    },
    hideLoginModal: () => {
        set(state => ({isVisibleLoginModal: false, code: undefined}))
    },
}))
