import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import {apiLoginSns} from '@api/auths/login/login'
import useSnackbar from '@hook/useSnackbar'
import {useLoginStore} from '@store/LoginStore'
import {useNavigate} from 'react-router-dom'
import {equalString} from '@util/strings'
import useResizeObserver from 'use-resize-observer'
import {useRouter} from 'next/router'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import Spinner from '@component/Spinner/Spinner'
import {SetLoginStateContext} from '@feature/common/components/LoginModal/LoginModalRouter'
import {useScript} from '@hook/useScript'
import {KAKAO_KEY} from '@constant/auth'
import {createLog} from '@util/logs'
import {shallow} from 'zustand/shallow'
import useLoginCallback from '@hook/useLoginCallback'
import {LoginModalContext} from '../../../../../../pages/login/index.page'
import {URL_BINANCE_FAST_API, URL_COINBASE_FAST_API} from '@constant/url'
import {addErrorLog, ERROR_LOG} from '@constant/log'
import useLogin from '@hook/useLogin'
import {showToast} from '@component/snackbar/WizToastMessage'
import {DarkModeStore} from '@store/DarkmodeStore'

interface IProps {
    className?: string
    methodType?: LoginMethodType
    loginSuccessCallback?: () => void
    dataCy?: string
}

export type LoginMethodType = 'google' | 'coinbase' | 'facebook' | 'apple' | 'kakaotalk' | 'binance' | 'email' | 'okx'

declare global {
    interface Window {
        Kakao: any
        AppleID: any
        google: any
    }
}

interface IMethodData {
    icon: string
    name: string
}

const ScreenLoginButtonLogin: React.FC<IProps> = ({className, methodType = 'email', loginSuccessCallback, dataCy}) => {
    const {t} = useTranslation()
    const {showErrorSnackbar, showSnackbar} = useSnackbar()
    const {id, login} = useLoginStore(
        state => ({
            id: state.id,
            login: state.login,
        }),
        shallow,
    )
    const {isDark} = DarkModeStore()
    const {getExchange} = useQueryGetExchanges()
    const navigate = useNavigate()
    const googleSignInButtonRef = useRef<HTMLDivElement>(null)
    const {ref} = useResizeObserver<HTMLDivElement>()
    const router = useRouter()
    const {isKakaoInit} = useContext(SetLoginStateContext)
    const [isLodingLoginInit, setIsLodingLoginInit] = useState(false)
    const {setIsLoginFailed, removeStorage: removeLoginFailedStorage} = useLoginCallback()
    const {checkUserBrowser} = useLogin()
    const {appliedReferral} = useContext(LoginModalContext)
    const {refCode} = useContext(SetLoginStateContext)

    const methodData = useMemo<IMethodData>(() => {
        switch (methodType) {
            case 'google':
                return {icon: '/images/login/ic_google.png', name: t('loginModal.login.google.text')}
            case 'coinbase':
                return {icon: '/images/login/ic_coinbase.png', name: t('loginModal.login.coinbase.text')}
            case 'binance':
                return {icon: '/images/login/ic_binance.png', name: t('loginModal.login.binance.text')}
            case 'okx':
                return {icon: '/images/login/ic_okx.png', name: t('loginModal.login.okx.text')}
            case 'facebook':
                return {icon: '/images/login/ic_facebook.png', name: t('loginModal.login.facebook.text')}
            case 'apple':
                return {
                    icon: isDark ? '/images/login/ic_apple_white.webp' : '/images/login/ic_apple.png',
                    name: t('loginModal.login.apple.text'),
                }
            case 'kakaotalk':
                return {icon: '/images/login/ic_kakao.png', name: t('loginModal.login.kakaotalk.text')}
            case 'email':
                return {icon: '/images/login/ic_email.png', name: t('loginModal.login.email.text')}
        }
    }, [t, methodType, isDark])

    const loginNetwork = useCallback(
        async (network: string, token: string, code?: string) => {
            let {data, error} = await apiLoginSns(network, token, code, appliedReferral || refCode)

            if (data?.success) {
                login(data.user_id, data.access_token, false)

                if (data?.is_first) {
                    navigate('/register_social_name')
                    // sendNaverLogConversion()
                } else {
                    loginSuccessCallback && loginSuccessCallback()
                }
                removeLoginFailedStorage()
            } else {
                const getLastLoginNetwork = localStorage.getItem('lastLoginNetwork')
                getLastLoginNetwork && createLog('loginError', `${getLastLoginNetwork}_login_error`)
                localStorage.removeItem('lastLoginNetwork')
                setIsLoginFailed(true)
                addErrorLog(ERROR_LOG.FAIL_LOGIN)
                showErrorSnackbar(error)
            }
        },
        [
            loginSuccessCallback,
            login,
            appliedReferral,
            refCode,
            removeLoginFailedStorage,
            navigate,
            setIsLoginFailed,
            showErrorSnackbar,
        ],
    )

    useEffect(() => {
        const coinbaseEventHandler = event => {
            if (event.data == undefined) return
            if ((event.data as any).hasOwnProperty('type') == false) return
            if (event.data.type != 'WebCoinbaseLogin') return

            let token: string = event.data.data as string
            loginNetwork('coinbase', token)
        }

        if (equalString(methodType, 'coinbase')) {
            window.addEventListener('message', coinbaseEventHandler, true)
            return () => {
                window.removeEventListener('message', coinbaseEventHandler, true)
            }
        }
    }, [methodType, loginNetwork])

    const onClick = useCallback(async () => {
        localStorage.setItem('lastLoginNetwork', methodType)
        switch (methodType) {
            case 'google':
                showToast(t('login.error.text.googleAuth'))
                break
            case 'coinbase':
                await router?.replace(getExchange('coinbase')?.oauth_login_link || URL_COINBASE_FAST_API)
                break
            case 'binance':
                await router?.replace(getExchange('binance')?.oauth_login_link || URL_BINANCE_FAST_API)
                break
            case 'okx':
                window.location.href = getExchange('okex')?.oauth_login_link || ''
                break
            case 'facebook':
                const checkLoginStatus = (response: any, afterLogin: boolean = false) => {
                    if (response == null || !response.hasOwnProperty('authResponse') || response.authResponse == null) {
                        if (!afterLogin)
                            window?.FB?.login(res => checkLoginStatus(res, true), {
                                scope: 'public_profile,email',
                            })
                        return
                    }

                    if (!afterLogin) {
                        window?.FB?.logout(() => window?.FB?.getLoginStatus(checkLoginStatus))
                        return
                    }

                    let accessToken: string = response['authResponse']['accessToken'] as string
                    if (accessToken == null) {
                        return
                    }

                    loginNetwork('facebook', accessToken)
                }

                window?.FB?.getLoginStatus(checkLoginStatus)
                break
            case 'apple':
                await window?.AppleID?.auth?.signIn()
                break
            case 'kakaotalk':
                const loginHandler = (auth: any) => {
                    if (auth == undefined) return
                    if (auth.hasOwnProperty('access_token') == false) return

                    let accessToken: string = auth.hasOwnProperty('access_token') ? auth['access_token'] : ''
                    if (accessToken == '') return
                    loginNetwork('kakaotalk', accessToken)
                }

                if (window.Kakao && window.Kakao.Auth) {
                    setIsLodingLoginInit(false)

                    window.Kakao.Auth.loginForm({
                        success: function (authObj) {
                            loginHandler(authObj)
                        },
                        fail: function (err) {
                            alert(JSON.stringify(err))
                            createLog('loginError', 'kakao_auth_error')
                        },
                    })
                } else {
                    if (!window.Kakao.isInitialized()) {
                        await window?.Kakao?.init(KAKAO_KEY)
                    }
                    if (isLodingLoginInit) {
                        createLog('loginError', 'kakao_login_error')
                    }
                    !isLodingLoginInit && setIsLodingLoginInit(true)
                }

                break
            case 'email':
                navigate('/login_email')
                createLog('event', 'login_click_email_method')
                break
        }
    }, [methodType, router, getExchange, isLodingLoginInit, loginNetwork, navigate, t])

    useEffect(() => {
        if (isLodingLoginInit) {
            setTimeout(async () => {
                await onClick()
            }, 500)
        }
    }, [isLodingLoginInit, onClick])

    const onSignInGoogle = useCallback(
        async (res: any) => {
            await loginNetwork('gmail', res.credential)
        },
        [loginNetwork],
    )

    // Load the script asynchronously
    const status = useScript(`https://accounts.google.com/gsi/client`, {
        removeOnUnmount: false,
    })

    useEffect(() => {
        if (methodType === 'google' && status === 'ready' && typeof window.google !== 'undefined') {
            window.google.accounts.id.initialize({
                client_id: '1024779936868-nvsso22f8he5ma6grf27q07s7c8g6ss3.apps.googleusercontent.com',
                callback: onSignInGoogle,
            })

            if (googleSignInButtonRef.current) {
                window.google.accounts.id.renderButton(googleSignInButtonRef.current, {
                    type: 'standard',
                    theme: 'outline',
                    text: 'signin_with',
                    size: 'large',
                    width: '500px',
                    shape: 'square',
                    click_listener: () => {
                        localStorage.setItem('lastLoginNetwork', 'google')
                    },
                })
            }
        }
    }, [status, onSignInGoogle, methodType])

    return (
        <div
            className={`${className} overflow-hidden dark:bg-bg_dark_white03 hover:bg-gray07 dark:hover:bg-dark_gray07 cursor-pointer`}
            ref={ref}
            data-cy={dataCy}>
            {equalString(methodType, 'google') && checkUserBrowser() ? (
                <div className="relative overflow-hidden">
                    <div className="absolute w-full opacity-[0.001] z-[1]">
                        <div id="google-login-api" className={'scale-[1.3]'} ref={googleSignInButtonRef} />
                    </div>
                    <button
                        className={
                            'flex  items-center justify-center px-[20px] py-[12.5px] gap-x-[10px] border rounded-[3px] border-gray05 dark:border-dark_gray05 w-full'
                        }
                        disabled={isLodingLoginInit}>
                        <WizImage src={methodData?.icon} width={20} height={20} />
                        <Text className={'flex-1 text-btn text-gray01 dark:text-dark_gray01'}>{methodData?.name}</Text>
                        {isLodingLoginInit && <Spinner visible={true} />}
                    </button>
                </div>
            ) : (
                <button
                    className={
                        'flex items-center justify-center px-[20px] py-[11px] gap-x-[10px] border rounded-[3px] border-gray05 dark:border-dark_gray05 w-full'
                    }
                    disabled={isLodingLoginInit}
                    onClick={onClick}>
                    <WizImage src={methodData?.icon} width={20} height={20} />
                    <Text className={'flex-1 text-btn text-gray01 dark:text-dark_gray01'}>{methodData?.name}</Text>
                    {isLodingLoginInit && <Spinner visible={true} size={'smallest'} />}
                </button>
            )}
        </div>
    )
}

export default ScreenLoginButtonLogin
