import React from 'react'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'

interface IProps {
    className?: string
    exchange?: string
    iconSize?: number
    textClassName?: string
    iconClassName?: string
    removeText?: boolean
}

const ExchangeTag: React.FC<IProps> = ({
    className,
    exchange,
    iconSize = 12,
    textClassName = 'text-body2',
    iconClassName,
    removeText,
}) => {
    const {getExchange} = useQueryGetExchanges()

    return (
        <div className={`flex items-center space-x-[5px] ${className}`}>
            <WizImage
                src={getExchange(exchange)?.icon}
                width={iconSize}
                height={iconSize}
                containerClassName={`${iconClassName} flex-none`}
                unoptimized={true}
            />
            {removeText ? null : (
                <Text
                    enablePreWhiteSpace={false}
                    className={`whitespace-nowrap  text-gray01 dark:text-dark_gray01 ${textClassName}`}>
                    {getExchange(exchange)?.display_name}
                </Text>
            )}
        </div>
    )
}

export default ExchangeTag
