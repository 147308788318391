import React, {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import {LoginContext} from '@feature/common/components/LoginModal/routes/LoginRoutes/LoginRoutes'
import {apiCheckEmailLogin} from '@api/auths/login/login'
import useSnackbar from '@hook/useSnackbar'
import {isEmpty} from '@util/strings'
import {apiSendLoginCode} from '@api/auths/login/sendCode'
import {emailValidation} from '@util/validation'
import useLoginCallback from '@hook/useLoginCallback'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenLoginEmail: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {email, setEmail} = useContext(LoginContext)
    const {showErrorSnackbar} = useSnackbar()
    const [isLoading, setIsLoading] = useState(false)
    const {removeStorage: removeLoginFailedStorage} = useLoginCallback()

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const onClickSubmit = async () => {
        if (!emailValidation(email)) {
            createLog('error', 'login_email_submit_failed_wrong_email_type')
            setInputState(InputState.ERROR)
            setInputErrorMessage(t('loginModal.emailLogin.submit.res.fail.wrongEmailType'))
            return
        }

        createLog('event', 'login_email_submit')
        setIsLoading(true)
        const {data, error} = await apiCheckEmailLogin(email)
        if (data) {
            if (data?.is_new_user === true) {
                navigate(`/register`, {state: {email}})
            } else if (data?.has_password === true) {
                navigate('/login_password')
            } else {
                await apiSendLoginCode(email)
                navigate('/login_code')
            }
            removeLoginFailedStorage()
        } else {
            createLog('error', 'login_email_submit_failed')
            showErrorSnackbar(error)
        }
        setIsLoading(false)
    }

    return (
        <LoginModalLayout closeModal={closeModal} title={t('loginModal.emailLogin.page.title')}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>{t('loginModal.emailLogin.desc')}</Text>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.emailLogin.label')}
            </Text>
            <WizInputText
                className={'mt-[5px]'}
                placeholder={t('loginModal.emailLogin.input.placeholder')}
                text={email}
                state={inputState}
                autoFocus={true}
                autoComplete={'username'}
                errorDescription={inputErrorMessage}
                onPressEnter={onClickSubmit}
                onChanged={text => {
                    setEmail(text)
                    setInputState(InputState.DEFAULT)
                }}
                dataCy={'input_email'}
            />
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.emailLogin.submit.text')}
                className={'mt-[20px] w-full'}
                isLoading={isLoading}
                onClick={onClickSubmit}
                disabled={isEmpty(email)}
                dataCy={'btn_confirm'}
            />
        </LoginModalLayout>
    )
}

export default ScreenLoginEmail
