import React from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import ScreenLoginButtonLogin from '@feature/common/components/LoginModal/component/ScreenLoginButtonLogin'
import useLoginModal from '@feature/common/components/LoginModal/hooks/useLoginModal'
import {useLocalStorage} from 'usehooks-ts'
import {IS_LOGIN_FAILED_STATE_KEY} from '@constant/key'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenLoginOtherMethod: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const [isLoginFailed] = useLocalStorage(IS_LOGIN_FAILED_STATE_KEY, false)
    const {redirectAfterLogin} = useLoginModal()

    const callbackLoginSuccess = async () => {
        closeModal()
        await redirectAfterLogin()
        createLog('event', 'login_modal_close')
    }

    return (
        <LoginModalLayout closeModal={closeModal} title={t('loginModal.login.page.title')} hideBackButton={true}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>{t('loginModal.login.page.desc')}</Text>
            <div className={'grid mt-[10px] gap-y-[5px]'}>
                <ScreenLoginButtonLogin
                    key={'google'}
                    methodType={'google'}
                    loginSuccessCallback={callbackLoginSuccess}
                />
                <ScreenLoginButtonLogin
                    key={'binance'}
                    methodType={'binance'}
                    loginSuccessCallback={callbackLoginSuccess}
                />
                <ScreenLoginButtonLogin key={'okx'} methodType={'okx'} loginSuccessCallback={callbackLoginSuccess} />
                <ScreenLoginButtonLogin key={'email'} methodType={'email'} />
                <ScreenLoginButtonLogin
                    key={'apple'}
                    methodType={'apple'}
                    loginSuccessCallback={callbackLoginSuccess}
                />
                <ScreenLoginButtonLogin
                    key={'kakaotalk'}
                    methodType={'kakaotalk'}
                    loginSuccessCallback={callbackLoginSuccess}
                />
                <ScreenLoginButtonLogin
                    key={'coinbase'}
                    methodType={'coinbase'}
                    loginSuccessCallback={callbackLoginSuccess}
                />
                <ScreenLoginButtonLogin
                    key={'facebook'}
                    methodType={'facebook'}
                    loginSuccessCallback={callbackLoginSuccess}
                />
                {isLoginFailed && (
                    <Text className={'text-red_shade dark:text-dark_red_shade text-body2 text-center'}>
                        {t('loginModal.login.failed.desc')}
                    </Text>
                )}
            </div>
        </LoginModalLayout>
    )
}

export default ScreenLoginOtherMethod
