// datadog.ts 코드
// datadog.ts 코드
import {datadogConfig} from '@constant/config'
import type {RumEvent} from '@datadog/browser-rum'
import {createLog} from '@util/logs'

type DatadogRumType = typeof import('@datadog/browser-rum')
let datadog: Awaited<ReturnType<typeof importDatadog>> | null = null

// 동적 import를 위한 함수
const importDatadog = async () => {
    try {
        return await import('@datadog/browser-rum')
    } catch (error) {
        createLog('error', 'datadog_connect_import_fail', error)
    }
}

// 최근에 전송된 에러 이벤트를 추적하기 위한 맵
const recentErrors = new Map<string, number>()

// 시간 임계값 상수 정의 (1분)
const TIME_THRESHOLD = 60000
// 스택 트레이스에서 파일명과 라인 번호 추출 함수
const extractFileAndLine = (stack?: string): string => {
    if (!stack) return ''

    // 정규식을 사용하여 파일명과 라인 번호 추출
    const regex = /(?:at\s+(?:.*?\s)?\()?([^():\s]+):(\d+):\d+\)?/

    for (const line of stack.split('\n')) {
        const match = line.match(regex)
        if (match) {
            const fileName = match[1].split('/').pop() || ''
            const lineNumber = match[2]
            return `${fileName}:${lineNumber}`
        }
    }

    return ''
}

// 이벤트를 고유하게 식별하기 위한 키 생성 함수
const generateErrorKey = (event: RumEvent & {error?: {message?: string; stack?: string}}): string => {
    const errorMessage = event.error?.message?.substring(0, 100) || 'Unknown Error'
    const fileAndLine = extractFileAndLine(event.error?.stack)
    return `${errorMessage}-${fileAndLine}`
}

// 중복 에러 여부를 판단하는 함수
const isDuplicateError = (event: RumEvent & {error?: {message?: string; stack?: string}}): boolean => {
    if (event.type !== 'error') {
        return false // 에러 이벤트가 아닌 경우 중복 체크하지 않음
    }

    const errorKey = generateErrorKey(event)
    const currentTime = Date.now()

    // 기존에 존재하는 에러인지 확인
    const lastSentTime = recentErrors.get(errorKey)
    if (lastSentTime && currentTime - lastSentTime < TIME_THRESHOLD) {
        return true // 중복 에러로 간주하여 전송하지 않음
    }

    // 현재 에러를 맵에 업데이트
    recentErrors.set(errorKey, currentTime)

    // 메모리 관리를 위해 오래된 에러 제거
    if (recentErrors.size > 50) {
        const expirationTime = currentTime - TIME_THRESHOLD
        recentErrors.forEach((time, key) => {
            if (time < expirationTime) {
                recentErrors.delete(key)
            }
        })
    }

    return false
}

const datadogOptions: Parameters<DatadogRumType['datadogRum']['init']>[0] = {
    applicationId: 'e5618f8f-771c-415f-913f-11c697d8f77a',
    clientToken: 'pubc09ac6373c5279b1634698622e5fa073',
    site: 'ap1.datadoghq.com',
    service: 'lot-web',
    version: '0.1.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackResources: false, // 리소스 추적 비활성화
    trackLongTasks: false, // 긴 작업 추적 비활성화
    trackUserInteractions: false, // 사용자 상호작용 추적 비활성화
    enablePrivacyForActionName: true,
    usePartitionedCrossSiteSessionCookie: true,
    allowedTracingUrls: ['https://leagueoftraders.io', 'https://api.leagueoftraders.io'],
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: event => !isDuplicateError(event),
}

export const initializeDatadog = async (): Promise<void> => {
    if (!datadog) {
        datadog = await importDatadog()
    }
    if (datadogConfig?.isEnabled && !datadog.datadogRum.getInitConfiguration()) {
        console.log('[Datadog Connect] RUM Event Init')
        datadog.datadogRum.init(datadogOptions)
    } else if (datadogConfig?.isEnabled) {
        createLog('error', 'datadog_rum_init_error')
    }
}
