import {ApiRes, ApiStatus} from '@type/api'
import {getCookie} from '@util/cookie'

export const API_URL = 'https://api.leagueoftraders.io/v3'
export const MEME_API_URL = 'https://apimeme.leagueoftraders.io/v1'

export interface IApiGetProps {
    cookies?: {}
    useAccessToken?: boolean
}

export interface IApiGetOtherURLProps {
    cookies?: {}
    host?: string
}

export const apiGet = async <T>(url: string, props?: IApiGetProps): Promise<ApiRes<T>> => {
    try {
        const endpoint = `${API_URL}/${url}`
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: getHeaders(props?.cookies),
        })
        const res = await response.json()
        if (res.status === ApiStatus.Success) {
            return {data: res.data, status: res.status, isManager: res?.isManager, pageInfo: res?.page_info}
        }
        return {data: undefined, status: res.status, error: res.error}
    } catch (e: any) {
        return {data: undefined, status: -1, error: e.toString()}
    }
}

export const apiGetMeme = async <T>(url: string, props?: IApiGetOtherURLProps): Promise<ApiRes<T>> => {
    try {
        const endpoint = `${MEME_API_URL}/${url}`
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            mode: 'cors',
        })
        const res = await response.json()
        if (res.status === ApiStatus.Success) {
            return {data: res.data, status: res.status, isManager: res?.isManager, pageInfo: res?.page_info}
        }
        return {data: undefined, status: res.status, error: res.error}
    } catch (e: any) {
        return {data: undefined, status: -1, error: e.toString()}
    }
}

export const apiPost = async <T>(url: string, body?: any, props?: IApiGetProps): Promise<ApiRes<T>> => {
    try {
        const endpoint = `${API_URL}/${url}`

        const useAccessToken = props?.useAccessToken ?? true

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: useAccessToken === false ? getUserLessHeaders(props?.cookies) : getHeaders(props?.cookies),
            body: JSON.stringify(body),
        })
        const res = await response.json()
        if (res.status === ApiStatus.Success) {
            return {data: res.data, status: res.status, isManager: res?.isManager}
        }
        return {data: undefined, status: res.status, error: res.error}
    } catch (e: any) {
        return {data: undefined, status: -1, error: e.toString()}
    }
}

// not tested yet
export const apiPut = async <T>(url: string, body?: any, accessToken?: string): Promise<ApiRes<T>> => {
    try {
        const endpoint = `${API_URL}/${url}`
        const response = await fetch(endpoint, {
            method: 'PUT',
            headers: getHeaders(accessToken),
            body: JSON.stringify(body),
        })
        const res = await response.json()
        if (res.status === ApiStatus.Success) {
            return {data: res.data, status: res.status}
        }
        return {data: undefined, status: res.status, error: res.error}
    } catch (e: any) {
        return {data: undefined, status: -1, error: e.toString()}
    }
}

// not tested yet
export const apiDelete = async <T>(url: string, body?: any, accessToken?: string): Promise<ApiRes<T>> => {
    try {
        const endpoint = `${API_URL}/${url}`
        const response = await fetch(endpoint, {
            method: 'DELETE',
            headers: getHeaders(accessToken),
            body: JSON.stringify(body),
        })
        const res = await response.json()
        if (res.status === ApiStatus.Success) {
            return {data: res.data, status: res.status, isManager: res?.isManager}
        }
        return {data: undefined, status: res.status, error: res.error}
    } catch (e: any) {
        return {data: undefined, status: -1, error: e.toString()}
    }
}

type Headers = {
    Accept: string
    'Content-Type': string
    'Access-Token': string
    source: string
    language: string
}

const getHeaders = (cookie?: any): Headers => {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Token': (cookie && cookie?.userToken) || getCookie('userToken'),
        source: 'web',
        language: ((cookie && cookie?.language) || getCookie('language')) ?? 'en',
    }
}

const getUserLessHeaders = (cookie?: any): Headers => {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Token': undefined,
        source: 'web',
        language: ((cookie && cookie?.language) || getCookie('language')) ?? 'en',
    }
}
