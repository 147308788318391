import {datadogRum} from '@datadog/browser-rum'

export const ERROR_LOG = {
    POINT_CLAIM: '[Custom Error] Daily Point Claim Error',
    POINT_API_SERVER_FAIL: '[Custom Error] Point API Error',
    POINT_CLAIM_FAIL: '[Custom Error] Point API Response Error',
    REFERRAL_POINT_CLAIM: '[Custom Error] Referral API Connect Point Claim Error',
    FAIL_CHANGE_USER_THUMBNAIL: '[Custom Error] Change User Thumbnail Error',
    FAIL_LOGIN: '[Custom Error] Login Fail Error',
    amplitude: {
        point: {
            error: {
                daily_check: 'account_daily_check_point_claim_failed',
                bonus: 'account_bonus_point_claim_failed',
                mission: 'account_mission_point_claim_failed',
                mission_telegram: 'account_mission_telegram_point_claim_failed',
                mission_x: 'account_mission_x_point_claim_failed',
                mission_x_server: 'account_mission_x_claim_server_failed',
                mission_referral: 'account_mission_referral_point_claim_failed',
            },
        },
    },
    AI_ANALYSIS_QUERY_FAIL: '[Custom Error] AI Analysis API Error',
    AI_ANALYSIS_FETCH_ORDER_FAIL: '[Custom Error] AI Analysis Fetch Key Order Error',
    copyTrading: {
        createFail: '[Custom Error] Copy Trading Create Fail',
        resumeFail: '[Custom Error] Copy Trading Resume Fail',
        deleteFail: '[Custom Error] Copy Trading Delete Fail',
        stopFail: '[Custom Error] Copy Trading Delete Fail',
        unlinkFail: '[Custom Error] Copy Trading Unlink Fail',
    },
    copyLeader: {
        createFail: '[Custom Error] Copy Leader Create Fail',
        stopFail: '[Custom Error] Copy Leader Stop Fail',
    },
    api: {
        create: {
            keyCheck: '[Custom Error] API Key Check Fail',
            register: '[Custom Error] API Register Fail',
        },
        renew: '[Custom Error] API Renew Fail',
    },
    timeline: {
        post: '[Custom Error] Timeline Create Post Fail',
    },
}

export const addErrorLog = (msg: string, option?: object) => {
    datadogRum?.addError(new Error(msg), option)
}
