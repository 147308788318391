import React from 'react'
import classNames from 'classnames'
import Text from '@component/Text/Text'
import {AnimatePresence, motion} from 'framer-motion'
import {Tab} from '@component/tabs/WizNavTabs'

const transition = {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.15,
}

interface IProps extends Tab {
    className?: string
    buttonClassName?: string
    borderClassName?: string
    index: number
    hoveredTab: number
    setHoveredTab: React.Dispatch<React.SetStateAction<number>>
    selectedTab: string
    setSelectedTab?: (input: string, index?: number) => void
    disableUnderlineAnimation?: boolean
}

const WizTabButtonItem: React.FC<IProps> = ({
    className,
    buttonClassName,
    index,
    id,
    label,
    renderRightArea,
    hoveredTab,
    setHoveredTab,
    selectedTab,
    setSelectedTab,
    disableUnderlineAnimation,
    onClick,
    borderClassName,
}) => {
    return (
        <motion.button
            className={classNames(
                `relative py-[13px] px-[20px] mr-[10px] sm:mr-0 text-ti1 cursor-pointer select-none transition-colors ${buttonClassName}`,
            )}
            onHoverStart={() => setHoveredTab(index)}
            onFocus={() => setHoveredTab(index)}
            onClick={event => {
                onClick?.(event)
                setSelectedTab?.(id?.at(0), index)
            }}>
            <div className={'flex items-center'} data-cy={`tab_${id?.at(0)}`}>
                <Text
                    className={classNames(
                        'z-20 text-ti1 sm:text-ti2 !whitespace-nowrap',
                        hoveredTab === index || id?.indexOf(selectedTab) >= 0
                            ? 'text-gray01 dark:text-dark_gray01'
                            : 'text-gray03 dark:text-dark_gray03',
                    )}>
                    {label}
                </Text>
                {renderRightArea}
            </div>

            {id?.indexOf(selectedTab) >= 0 && disableUnderlineAnimation && (
                <div
                    className={`absolute z-10 h-[3px] left-[20px] right-[20px] sm:left-[10px] sm:right-[10px] bottom-0 bg-gray01 dark:bg-dark_gray01 ${borderClassName}`}
                />
            )}
            {id?.indexOf(selectedTab) >= 0 && !disableUnderlineAnimation ? (
                <motion.div
                    transition={transition}
                    layoutId="underline"
                    className={`absolute z-10 h-[3px] left-[20px] right-[20px] sm:left-[10px] sm:right-[10px] bottom-0 bg-gray01 dark:bg-dark_gray01 ${borderClassName}`}
                />
            ) : null}
            <AnimatePresence>
                {index === hoveredTab ? (
                    <motion.div
                        className="absolute bottom-0 left-0 right-0 top-0 z-10 rounded-[5px] sm:rounded-[3px] bg-transparent01 dark:bg-bg_dark_white01"
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        exit={{
                            opacity: 0,
                        }}
                        transition={transition}
                        layoutId="hover"
                    />
                ) : null}
            </AnimatePresence>
        </motion.button>
    )
}
export default React.memo(WizTabButtonItem)
