import * as amplitude from '@amplitude/analytics-browser'
import {IMe, IProfile} from '@type/profile'

const identifyEvent = new amplitude.Identify()

export function getIsOverTimeDays(createdAt: number, day: number) {
    const DAYS_SECONDS = 86400 * day
    const currentTime = Math.round(new Date().getTime() / 1000)

    return currentTime - createdAt >= DAYS_SECONDS
}

export function setUserMeProperties(me?: IMe, profile?: IProfile) {
    if (!me) return
    identifyEvent.set('name', me.name)
    identifyEvent.set('phone_confirmed', me.phone_confirmed)
    identifyEvent.set('email_confirmed', me.email_confirmed)
    identifyEvent.set('thumbnail', me.thumbnail)
    identifyEvent.set('followers', me.followers || 0)
    identifyEvent.set('following', me.following || 0)
    identifyEvent.set('active_exchange', me?.active_exchanges)
    identifyEvent.set('active_exchange_count', me?.active_exchanges?.length || 0)
    identifyEvent.set('created_at', me.created_at)
    identifyEvent.set('isOverThreeDays', getIsOverTimeDays(me?.created_at, 3))
    identifyEvent.set('privacy_level', me?.privacy_level)
    identifyEvent.set('change_count', me?.change_count || 0)
    identifyEvent.set('flags', me?.flags)
    identifyEvent.set('permission', me?.permissions)
    identifyEvent.set('key_count', me?.keys?.length || 0)
    identifyEvent.set('login_type', me?.network || 'email')
    identifyEvent.set('has_balance', !!profile?.total_value)

    amplitude.identify(identifyEvent)
}

export function setUserProperty(key: string, value: any) {
    identifyEvent.set(key, value)
    amplitude.identify(identifyEvent)
}

export function setLogUserLogout() {
    identifyEvent.clearAll()
    amplitude.identify(identifyEvent)
}
