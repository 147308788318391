import React, {useCallback, useMemo, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import UserThumbnail from '@component/images/UserThumbnail'
import WizDropdown from '../../../components/dropdowns/WizDropdown'
import IconEdit from '@svg/common/ic_edit.svg'
import {apiUploadImage} from '@api/core/image/uploadImage'
import {apiEditThumbnail} from '@api/user/account/editThumbnail'
import useSnackbar from '@hook/useSnackbar'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import ProTag from '@component/tags/ProTag'
import {ILoginInfoData} from '@store/LoginStore'
import WizIcon from '@component/images/WizIcon'
import {useRouter} from 'next/router'
import AccountNavigationTabs from '@feature/account/layouts/AccountNavigationTabs'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'
import {addErrorLog, ERROR_LOG} from '@constant/log'
import {IMAGE_ACCEPT} from '@util/image'
import WizLink from '@component/link/WizLink'
import {URL_PORTFOLIO, URL_TIMELINE_USER} from '@constant/url'
import dynamic from 'next/dynamic'

export const AccountImportantAlertContainerCSR = dynamic(
    import('@feature/account/layouts/AccountImportantAlertContainer'),
    {
        ssr: false,
    },
)

interface IProps {
    children?: React.ReactNode
    layoutClassName?: string
    hideOnTablet?: boolean
}

const AccountLayout = ({
    layoutClassName = 'layout-desktop_1142 default_page_padding pt-[0px] sm:px-[20px]',
    hideOnTablet,
    children,
}: IProps) => {
    const {t} = useTranslation()
    const {showSnackbar, showErrorSnackbar} = useSnackbar()
    const router = useRouter()

    const {data: me, refetch: refreshMe, isLoading} = useQueryFetchMe(undefined, false)

    const userLocalName = useMemo(() => {
        return me?.nicknames?.find(item => item.language === router?.locale)?.nickname ?? me?.name
    }, [me, router?.locale])
    const imageFileRef = useRef<any>()

    const changeImage = async e => {
        const file = e?.target?.files[0]

        if (file) {
            const result = await apiUploadImage(file)
            if (result.success) {
                const {data, error} = await apiEditThumbnail(result.image_url)
                if (data?.success) {
                    showSnackbar(t('account.thumbnail.changeImage.success'))
                    const prevAccount: ILoginInfoData = JSON.parse(localStorage.getItem('user_accounts'))?.find(
                        item => item?.id === me?.id,
                    )

                    const accountsFilterArr: ILoginInfoData[] = JSON.parse(
                        localStorage.getItem('user_accounts'),
                    )?.filter(item => item?.id !== me?.id)

                    localStorage.setItem(
                        'user_accounts',
                        JSON.stringify([
                            ...(Array.isArray(accountsFilterArr) ? accountsFilterArr : []),
                            {
                                ...prevAccount,
                                thumbnail: result?.image_url,
                            },
                        ]),
                    )
                    localStorage?.removeItem('account_profile_setting_alert')
                    refreshMe()
                    return
                } else {
                    showErrorSnackbar(error)
                    addErrorLog(ERROR_LOG.FAIL_CHANGE_USER_THUMBNAIL)
                }
            }
        }

        e.target.value = ''
        showSnackbar(t('account.thumbnail.changeImage.fail'))
        return
    }

    const resetDefaultImage = useCallback(async () => {
        const {data, error} = await apiEditThumbnail('')
        if (data) {
            showSnackbar(t('account.thumbnail.changeImage.success'))
            refreshMe()
        } else {
            showErrorSnackbar(error)
        }
    }, [showSnackbar, showErrorSnackbar, refreshMe, t])

    const onPickImage = () => {
        imageFileRef && imageFileRef.current.click()
    }
    const imageMenuOptionList = useMemo(
        () => [
            {title: t('account.thumbnail.menu.pickImage'), onClick: onPickImage},
            {title: t('account.thumbnail.menu.setDefault'), onClick: resetDefaultImage},
        ],
        [t, resetDefaultImage],
    )

    return (
        <div className={`${layoutClassName} ${hideOnTablet && 'md:!px-0 sm:!px-0'}`} data-cy={'account_layout'}>
            <article
                className={`grid ${
                    hideOnTablet && 'md:hidden'
                } grid-cols-[auto_447px] gap-x-[20px] md:grid-cols-1 md:gap-y-[30px] sm:gap-y-[10px] items-start my-[30px] sm:mb-[20px]`}>
                {/* Account Information */}
                <section className={'flex items-center gap-x-[12px] sm:gap-x-[10px]'}>
                    <div className={'flex-none'}>
                        <WizDropdown
                            containerClassName={'rounded-full'}
                            downViewAlign={'left'}
                            optionList={imageMenuOptionList}
                            renderView={
                                <div className={'relative '}>
                                    {isLoading ? (
                                        <SkeletonBlock
                                            className={'w-[70px] h-[70px] sm:w-[50px] sm:h-[50px] rounded-full'}
                                            sizeClassName={'h-full rounded-full'}
                                        />
                                    ) : (
                                        <>
                                            <UserThumbnail
                                                src={me?.thumbnail}
                                                size={70}
                                                isPro={me?.is_pro}
                                                containerClassName={'sm:hidden'}
                                            />
                                            <UserThumbnail
                                                src={me?.thumbnail}
                                                size={45}
                                                isPro={me?.is_pro}
                                                containerClassName={'hidden sm:block'}
                                            />
                                        </>
                                    )}
                                    <div className="absolute z-10 right-0 bottom-0">
                                        <WizIcon
                                            className={
                                                'bg-white dark:bg-bg_dark_background border border-gray07 dark:border-dark_gray07 p-[2px] hover:bg-gray06 dark:hover:bg-dark_gray06'
                                            }>
                                            <IconEdit
                                                className={'w-[16px] sm:w-[10px] fill-gray02 dark:fill-dark_gray02'}
                                            />
                                        </WizIcon>
                                    </div>
                                </div>
                            }
                        />
                        <input
                            type={'file'}
                            style={{display: 'none'}}
                            accept={IMAGE_ACCEPT}
                            onChange={e => {
                                changeImage(e)
                            }}
                            ref={imageFileRef}
                        />
                    </div>
                    <div>
                        <div className={'flex gap-x-[5px] items-center'}>
                            {isLoading ? (
                                <SkeletonBlock className={'w-[200px] h-[36px] mx-auto'} sizeClassName={'h-[24px]'} />
                            ) : (
                                <Text
                                    className={
                                        'text-h3 sm:text-ti2 text-left break-all text-gray01 dark:text-dark_gray01'
                                    }>
                                    {userLocalName || ''}{' '}
                                    {me?.is_pro && <ProTag size={'small'} type={'text'} className={'ml-[3px]'} />}
                                </Text>
                            )}
                        </div>
                        <div className={'flex items-center gap-x-[15px]'}>
                            <div className={'flex items-center mt-[5px] sm:mt-[3px]'}>
                                <WizLink
                                    text={t('account.myPortfolio')}
                                    href={URL_PORTFOLIO(me?.id)}
                                    renderArrow={true}
                                    lineClassName={'bg-gray02 dark:bg-dark_gray02'}
                                />
                            </div>
                            <div className={'flex items-center mt-[5px] sm:mt-[3px]'}>
                                <WizLink
                                    text={t('account.myPosts')}
                                    href={URL_TIMELINE_USER(me?.id)}
                                    renderArrow={true}
                                    lineClassName={'bg-gray02 dark:bg-dark_gray02'}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Account Important Alert */}
                <AccountImportantAlertContainerCSR />
            </article>
            <div className={'flex justify-center pt-[10px] md:hidden'}>
                <AccountNavigationTabs />
            </div>
            {children}
        </div>
    )
}

export default AccountLayout
