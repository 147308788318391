import {BotStatus} from '@api/key/copyfolio/getKeyCopyfolio'

export interface IProfile {
    id: string
    name: string
    flags: string[]
    thumbnail: string
    followers: number
    following: number
    status: string
    active_exchanges: string[]
    is_following: boolean
    is_receive_notification: boolean
    is_hidden: boolean
    copy_allowed: boolean
    privacy_level: UserPrivacy
    profit_summary: IProfitSummary
    type: ProfileType
    analytics: IAnalytics
    asset_rating: string
    total_value: number
    accumulated_pnl: number
    token_distribution: ITokenDistribution[]
    key_summary: IKeySummary[]
    risk: number
    daily_score: number | 0
    daily_earn: number | 0
    daily_rank: number | 0
    weekly_score: number | 0
    weekly_earn: number | 0
    weekly_rank: number | 0
    monthly_score: number | 0
    monthly_earn: number | 0
    monthly_rank: number | 0
    monthly_aprofit_rank: number | 0
    monthly_aprofit_score: number | 0
    all_time_score: number | 0
    all_time_earn: number | 0
    all_time_rank: number | 0
    all_time_aprofit_rank: number | 0
    all_time_aprofit_score: number | 0
    balance_score: number | 0
    balance_rank: number | 0
    copy_available_keys?: number | 0
}

export interface IMe {
    id: string
    status: string
    email: string
    network: LoginNetwork
    name: string
    phone: string
    phone_confirmed: boolean
    thumbnail: string
    email_confirmed: boolean
    pay_code: string
    followers: number
    following: number
    active_exchanges: string[]
    created_at: number
    my_referral_code: string
    used_referral_code?: string
    change_count?: number
    privacy_level: 0 | 1 | 2 | 3
    flags?: string[]
    preferences: IUserPreferences
    permissions?: string[]
    keys?: IKey[]
    nicknames: INickname[]
    wallets?: IWallets
    last_name_change: string
    last_name_change_other: string
    has_totp: boolean
    leader_earning?: number
    copy_earning?: number
    has_password?: boolean
    is_pro?: boolean
    is_paid_leader?: boolean
    pro_until?: string
    pending_invoices?: number
    pro_method?: PROPaymentMethod
    referral_count?: number
    isManager?: boolean
}

export interface IProfitSummary {
    1?: {profit?: number; adjusted_profit?: number; earning?: number; rank?: number}
    7?: {profit?: number; adjusted_profit?: number; earning?: number; rank?: number}
    30?: {profit?: number; adjusted_profit?: number; earning?: number; rank?: number}
    90?: {profit: number}
    180?: {profit: number}
    365?: {profit: number}
    1000?: {profit?: number; adjusted_profit?: number; earning?: number; rank?: number}
}

export interface IWallets {
    total_value: number
    wallets: IWallet[]
}

export interface IWallet {
    id: string
    coin: string
    logo?: string
    balance: number
    price: number
    value: number
}

export interface IKey {
    key_id: string
    name: string
    exchange: string
    api_key: string
    is_active: boolean
    is_copy_bot: boolean
    update_available: boolean
    permission: KeyPermission
    state: KeyState
    expire_at?: number
    is_first_time?: boolean
    pro_ip_status?: ProIpStatus
    ip_status?: IpStatus
    missing_permissions: string[]
    created_at?: number
}

export enum KeyPermission {
    ReadOnly = 1,
    Order = 2,
}

export enum ProIpStatus {
    Set = 'set',
    NotSet = 'not-set',
}

export enum IpStatus {
    Set = 'set',
    NotSet = 'not-set',
}

export enum PROPaymentMethod {
    iOS = 'ios',
    android = 'android',
    crypto = 'crypto',
}

export enum LoginNetwork {
    FACEBOOK = 'facebook',
    APPLE = 'apple',
    GMAIL = 'gmail',
    EMAIL = 'email',
    KAKAOTALK = 'kakaotalk',
    BINANCE = 'binance',
    COINBASE = 'coinbase',
    OKX = 'okx',
}

export interface INickname {
    language: string
    nickname: string
}

export interface IUserPreferences {
    language?: string
    ads_msg_enabled?: number
    ads_msg_night_enabled?: number
    ads_msg_enabled_date?: number
    ads_msg_night_enabled_date?: number
    auto_join_league?: boolean
    pause_until_time?: number
    official_posts_enabled?: boolean
    event_msg_enabled?: boolean
    portfolio_msg_enabled?: boolean
    follow_msg_enabled?: boolean
    post_like_msg_enabled?: boolean
    comments_msg_enabled?: boolean
    comment_like_msg_enabled?: boolean
    reply_msg_enabled?: boolean
    stop_loss_msg_enabled?: boolean
    take_profit_msg_enabled?: boolean
    copy_trading_pnl_msg_enabled?: boolean
    new_copier_msg_enabled?: boolean
    deposit_msg_enabled?: boolean
    withdraw_msg_enabled?: boolean
    fee_receipt_msg_enabled?: boolean
    fee_payout_msg_enabled?: boolean
    league_start_msg_enabled?: boolean
    league_end_msg_enabled?: boolean
    rank_top_10_msg_enabled?: boolean
    rank_top_3_msg_enabled?: boolean
    point_daily_msg_enabled?: boolean
}

export enum KeyState {
    CanUse = 1,
    NoPermission = 2,
    UsedByAnotherBot = 3,
    IsCopyLeader = 4,
}

export enum UserPrivacy {
    OpenToSubscriber = 0,
    OpenToAll = 1,
    Private = 2,
    CompletelyPrivate = 3,
}

export enum ProfileType {
    Normal = 0,
    Official = 1,
    Media = 2,
}

interface ISharpeRatio {
    accuracy?: number
    sharpe_ratio?: number
    adjusted_sharpe_ratio?: number
}

export interface IAnalytics {
    average_hold_seconds_per_month: number
    average_hold_seconds_note: string
    average_trade_count_per_month: number
    average_trade_count_note: string
    volatility_per_month: number
    volatility_note: string
    max_draw_down_per_month: number
    max_draw_down_note: string
    win_trade: number
    lose_trade: number
    created_at: number
    sharpe_ratio?: ISharpeRatio
}

export interface ITokenDistribution {
    symbol: string
    amount?: number
    percentage: number
    color_code?: string
    logo?: string | any
    name?: string
    price?: number
}

export interface IKeySummary {
    key_id: string
    key_name?: string
    exchange: string
    start_at: number
    end_at: number
    total_value: number
    initial_value: number
    pnl: number
    withdraw: number
    deposit: number
    profit_rate: number
    assets?: IAsset[]
    open_orders?: IOpenOrder[]
    created_at?: number
    balance: number
    earn: number
    initial_balance: number
    is_copier: boolean
    is_leader: boolean
    copier_count?: number
    max_copier?: number
    copier_status?: BotStatus
    is_first_time?: boolean
}

export interface IAsset {
    symbol: string
    name?: string
    amount: number
    avg_buy_price: number
    current_price: number
    price_in_base: number
    leverage: number
    side?: string
    cross_margin: boolean
    is_margin: boolean
    is_position?: boolean
    unrealised_pnl: number
    exchange: string
    is_edit: boolean
    key_id: string
    logo: string
    profit: number
    initial_value: number
    current_value: number
    pnl: number
    avg_entry_price: number
    is_base: boolean
    extra?: string
    wallet?: string
    key_name?: string //populated on client-side only
}

export interface IOpenOrder {
    id: string
    symbol: string
    side: string
    exchange: string
    key_id: string
    price: number
    amount: number
    type: string
    logo: string
    created_at: number
    current_price: number
}
