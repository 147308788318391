import React, {ReactNode, useState} from 'react'
import {LayoutGroup, motion} from 'framer-motion'
import WizTabButtonItem from '@component/tabs/WizTabButtonItem'
import Link from 'next/link'

export type Tab = {
    label: string
    id: string[]
    link?: string
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    renderRightArea?: JSX.Element
    children?: ReactNode
}

type Props = {
    type?: 'tabs' | 'links'
    className?: string
    buttonClassName?: string
    tabs: Tab[]
    selectedTab: string
    setSelectedTab?: (input: string, index?: number) => void
    disableUnderlineAnimation?: boolean
}

const WizPortfolioNavTabs = ({
    type = 'tabs',
    className,
    buttonClassName,
    tabs,
    selectedTab,
    setSelectedTab,
    disableUnderlineAnimation = false,
}: Props): JSX.Element => {
    const [hoveredTab, setHoveredTab] = useState<number | null>(null)
    return (
        <motion.nav
            className={`relative flex flex-shrink-0 justify-center gap-x-[40px] sm:gap-x-[0px] sm:justify-around items-center sm:border-b sm:border-b-gray07 dark:sm:border-b-dark_gray07 z-0 ${className}`}
            onHoverEnd={() => setHoveredTab(null)}>
            <LayoutGroup id="tabs">
                {tabs.map((item, i) => {
                    return type === 'links' ? (
                        <Link href={item?.link} key={i} shallow={true}>
                            <WizTabButtonItem
                                {...item}
                                index={i}
                                buttonClassName={`!px-[10px] !mr-[0px] ${buttonClassName}`}
                                borderClassName={'!left-[10px] !right-[10px]'}
                                hoveredTab={hoveredTab}
                                selectedTab={selectedTab}
                                setHoveredTab={setHoveredTab}
                                setSelectedTab={setSelectedTab}
                                disableUnderlineAnimation={disableUnderlineAnimation}
                            />
                        </Link>
                    ) : (
                        <WizTabButtonItem
                            {...item}
                            key={i}
                            index={i}
                            buttonClassName={`${buttonClassName}`}
                            hoveredTab={hoveredTab}
                            selectedTab={selectedTab}
                            setHoveredTab={setHoveredTab}
                            setSelectedTab={setSelectedTab}
                            disableUnderlineAnimation={disableUnderlineAnimation}
                        />
                    )
                })}
            </LayoutGroup>
        </motion.nav>
    )
}
export default React.memo(WizPortfolioNavTabs)
