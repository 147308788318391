import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizPopupModal from '@component/modal/WizPopupModal'
import Text from '@component/Text/Text'
import TagText from '@component/Text/TagText'
import WizImage from '@component/images/WizImage'
import {useRouter} from 'next/router'
import img_meme_phishing_pc_ko_light from '@image/meme/phishing/img_meme_phishing_pc_ko_light.webp'
import img_meme_phishing_pc_ko_dark from '@image/meme/phishing/img_meme_phishing_pc_ko_dark.webp'
import img_meme_phishing_mo_ko_light from '@image/meme/phishing/img_meme_phishing_mo_ko_light.webp'
import img_meme_phishing_mo_ko_dark from '@image/meme/phishing/img_meme_phishing_mo_ko_dark.webp'
import img_meme_phishing_pc_en_light from '@image/meme/phishing/img_meme_phishing_pc_en_light.webp'
import img_meme_phishing_pc_en_dark from '@image/meme/phishing/img_meme_phishing_pc_en_dark.webp'
import img_meme_phishing_mo_en_light from '@image/meme/phishing/img_meme_phishing_mo_en_light.webp'
import img_meme_phishing_mo_en_dark from '@image/meme/phishing/img_meme_phishing_mo_en_dark.webp'
import WizButton from '@component/buttons/WizButton'

interface IProps {}

const MemePhishingSiteModal = ({}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const router = useRouter()
    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    return (
        <WizPopupModal
            size={'600'}
            ref={modalRef}
            popupClassName={'sm:!w-full'}
            layoutClassName={'md:!mx-[10px] sm:!mx-[20px]'}>
            <Text className={'text-h3 sm:text-h4 text-gray01 dark:text-dark_gray01 text-center sm:mt-[10px]'}>
                {t('modal.meme.phishing.title')}
            </Text>
            <TagText className={'mt-[5px] text-body1 sm:text-body2 text-gray02 dark:text-dark_gray02 text-center'}>
                {t('modal.meme.phishing.desc')}
            </TagText>
            <div className={'mt-[20px] sm:mt-[10px]'}>
                {router?.locale === 'ko' ? (
                    <>
                        <WizImage src={img_meme_phishing_pc_ko_light} containerClassName={'sm:hidden dark:hidden'} />
                        <WizImage
                            src={img_meme_phishing_pc_ko_dark}
                            containerClassName={'sm:hidden hidden dark:block dark:sm:hidden'}
                        />
                        <WizImage
                            src={img_meme_phishing_mo_ko_light}
                            containerClassName={'hidden sm:block sm:dark:hidden'}
                        />
                        <WizImage src={img_meme_phishing_mo_ko_dark} containerClassName={'hidden sm:dark:block'} />
                    </>
                ) : (
                    <>
                        <WizImage src={img_meme_phishing_pc_en_light} containerClassName={'sm:hidden dark:hidden'} />
                        <WizImage
                            src={img_meme_phishing_pc_en_dark}
                            containerClassName={'sm:hidden hidden dark:block dark:sm:hidden'}
                        />
                        <WizImage
                            src={img_meme_phishing_mo_en_light}
                            containerClassName={'hidden sm:block sm:dark:hidden'}
                        />
                        <WizImage src={img_meme_phishing_mo_en_dark} containerClassName={'hidden sm:dark:block'} />
                    </>
                )}
                <div
                    className={
                        'flex sm:flex-col-reverse sm:gap-y-[10px] justify-center items-center gap-x-[10px] mt-[30px]'
                    }>
                    <WizButton
                        text={t('modal.meme.phishing.notShow.button')}
                        className={'w-full'}
                        buttonType={'border'}
                        onClick={() => {
                            localStorage?.setItem('not_show_meme_phishing_modal', 'true')
                            modalRef?.current?.close()
                        }}
                    />
                    <WizButton
                        text={t('modal.meme.phishing.button')}
                        className={'w-full'}
                        buttonType={'primary'}
                        onClick={() => {
                            modalRef?.current?.close()
                        }}
                    />
                </div>
            </div>
        </WizPopupModal>
    )
}

export default React.forwardRef(MemePhishingSiteModal)
