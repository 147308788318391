import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from 'next-i18next'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import {useNavigate} from 'react-router-dom'
import {RegisterContext} from '@feature/common/components/LoginModal/routes/RegisterRoutes/RegisterRoutes'
import {equalString, isEmpty, isNotEmpty} from '@util/strings'
import {apiEditPassword} from '@api/user/account/editPassword'
import useNavigation from '@hook/useNavigation'
import useSnackbar from '@hook/useSnackbar'
import WizProgressBar from '@component/progress/WizProgressBar'
import {
    PasswordSecureType,
    PasswordSecureTypes,
    usePasswordSecure,
} from '@feature/common/components/LoginModal/screen/hooks/usePasswordSecure'
import {
    PASSWORD_CHAR_EXP,
    PASSWORD_CHECK_MEDIUM_EXP,
    PASSWORD_CHECK_STRONG_EXP,
    PASSWORD_CHECK_WEEK_EXP,
} from '@util/validation'
import TagText from '@component/Text/TagText'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenRegisterSetPassword: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {showErrorSnackbar, showSnackbar} = useSnackbar()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingSkip, setIsLoadingSkip] = useState(false)
    const {password, setPassword, confirmPassword, setConfirmPassword} = useContext(RegisterContext)
    const [passwordSecure, setPasswordSecure] = useState<PasswordSecureType>(PasswordSecureTypes.NONE)
    const [isWrongSecure, setIsWrongSecure] = useState(false)
    const {progress, progressStyle, secureText, secureTextStyle} = usePasswordSecure(passwordSecure)

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const {goLeaderboard} = useNavigation()

    const onClickSubmit = async () => {
        if (isDisableSubmitButton) return
        createLog('event', 'login_email_register_password_submit')

        setIsLoading(true)
        const {data, error} = await apiEditPassword(undefined, password)
        if (data) {
            navigate('/register_done')
        } else {
            createLog('error', 'login_email_register_password_submit_failed')
            showErrorSnackbar(error)
        }
        setIsLoading(false)
    }

    const onClickSkip = async () => {
        createLog('event', 'login_email_register_password_skip')
        setIsLoadingSkip(true)
        navigate('/register_done')
        setIsLoadingSkip(false)
    }

    const isDisableSubmitButton = useMemo(
        () =>
            isEmpty(password) ||
            isEmpty(confirmPassword) ||
            !equalString(password, confirmPassword) ||
            passwordSecure === PasswordSecureTypes.NONE,
        [password, confirmPassword, passwordSecure],
    )

    useEffect(() => {
        if (isNotEmpty(password) && isNotEmpty(confirmPassword) && !equalString(password, confirmPassword)) {
            setInputState(InputState.ERROR)
            setInputErrorMessage(t('loginModal.registerPassword.submit.res.fail.incorrectCode'))
        } else {
            setInputState(InputState.DEFAULT)
        }
    }, [password, confirmPassword, t])

    const checkPasswordSecure = (password: string) => {
        let newSecure: PasswordSecureType = PasswordSecureTypes.NONE
        const isWrongSecurePass = password.length > 0 && !password.match(PASSWORD_CHAR_EXP)
        if (password.match(PASSWORD_CHECK_WEEK_EXP)) {
            newSecure = PasswordSecureTypes.WEAK
        }
        if (password.match(PASSWORD_CHECK_MEDIUM_EXP)) {
            newSecure = PasswordSecureTypes.MEDIUM
        }
        if (password.match(PASSWORD_CHECK_STRONG_EXP)) {
            newSecure = PasswordSecureTypes.STRONG
        }

        setIsWrongSecure(isWrongSecurePass)
        setPasswordSecure(newSecure)
        setPassword(password)
    }

    return (
        <LoginModalLayout
            closeModal={() => {
                closeModal()
                goLeaderboard()
            }}
            title={t('loginModal.registerPassword.page.title')}
            hideBackButton={true}>
            <div className={'space-y-[10px]'}>
                <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                    {t('loginModal.registerPassword.desc')}
                </Text>
                <div>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('loginModal.registerPassword.password.label')}
                    </Text>
                    <WizInputText
                        className={'mt-[5px]'}
                        type={'password'}
                        text={password}
                        onChanged={checkPasswordSecure}
                        placeholder={t('loginModal.registerPassword.password.label')}
                        autoFocus={true}
                    />
                </div>
                <div className={'space-y-[3px]'}>
                    <WizProgressBar
                        width={progress}
                        className={'bg-gray06 dark:bg-dark_gray06'}
                        barClassName={`h-[6px] ${progressStyle}`}
                    />
                    <div>
                        <TagText className={`text-body3 ${secureTextStyle}`}>{secureText}</TagText>
                        {isWrongSecure && (
                            <Text className={`text-body3 text-red_shade dark:text_dark_red_shade`}>
                                {t('loginModal.password.secure.wrong.text')}
                            </Text>
                        )}
                    </div>
                </div>
                <div>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[20px]'}>
                        {t('loginModal.registerPassword.confirmPassword.label')}
                    </Text>
                    <WizInputText
                        className={'mt-[5px]'}
                        type={'password'}
                        text={confirmPassword}
                        state={inputState}
                        errorDescription={inputErrorMessage}
                        onChanged={setConfirmPassword}
                        onPressEnter={onClickSubmit}
                        placeholder={t('loginModal.registerPassword.confirmPassword.input.placeholder')}
                    />
                </div>
                <div>
                    <WizButton
                        buttonType={'primary'}
                        text={t('loginModal.registerPassword.submit.setPassword.text')}
                        className={'w-full mt-[20px]'}
                        disabled={isDisableSubmitButton}
                        isLoading={isLoading}
                        onClick={onClickSubmit}
                    />
                    <WizButton
                        buttonType={'gray'}
                        text={t('loginModal.registerPassword.submit.skip.text')}
                        className={'w-full mt-[10px]'}
                        isLoading={isLoadingSkip}
                        onClick={onClickSkip}
                    />
                </div>
            </div>
        </LoginModalLayout>
    )
}

export default ScreenRegisterSetPassword
