import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from 'next-i18next'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import {NICKNAME_MAX_LENGTH, NICKNAME_MIN_LENGTH} from '@constant/auth'
import InputTextCount from '@component/inputs/InputTextCount'
import {useLocation, useNavigate} from 'react-router-dom'
import {RegisterContext} from '@feature/common/components/LoginModal/routes/RegisterRoutes/RegisterRoutes'
import {equalString, isEmpty} from '@util/strings'
import {ENGLISH_NICKNAME_FORBIDDEN_REGEX} from '@constant/reg'
import {apiCheckUserName} from '@api/auths/register/checkUserName'
import useSnackbar from '@hook/useSnackbar'
import {apiSendEmailConfirmCode} from '@api/auths/register/sendEmailConfirmCode'
import {createLog} from '@util/logs'
import {useRouter} from 'next/router'
import IconArrowDown from '@svg/common/ic_btn_down.svg'
import TagText from '@component/Text/TagText'
import {apiCheckReferral} from '@api/auths/register/checkReferral'

interface IProps extends ILoginModalProps {}

const ScreenRegisterName: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const router = useRouter()

    let {state} = useLocation()
    const {email: emailReceived} = state
    const [isLoading, setIsLoading] = useState(false)
    const {showErrorSnackbar, showSnackbar} = useSnackbar()

    const {email, setEmail, name, setName, code, setCode, setPassword, setConfirmPassword, referral, setReferral} =
        useContext(RegisterContext)

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [referralInputState, setReferralInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')
    const [referralInputErrorMessage, setReferralInputErrorMessage] = useState('')
    const [isOpenReferral, setIsOpenReferral] = useState(false)

    useEffect(() => {
        if (!equalString(emailReceived, email)) {
            setEmail(emailReceived)
            setName('')
            setCode('')
            setPassword('')
            setConfirmPassword('')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailReceived])

    const isDisableSubmitButton = useMemo(
        () =>
            isEmpty(name) ||
            name?.length < NICKNAME_MIN_LENGTH ||
            name?.length > NICKNAME_MAX_LENGTH ||
            inputState === InputState.ERROR ||
            referralInputState === InputState.ERROR,
        [name, inputState, referralInputState],
    )

    const onClickSubmit = async () => {
        if (isDisableSubmitButton) return
        createLog('event', 'login_email_register_name_submit')

        setIsLoading(true)
        const {data, error} = await apiCheckUserName(name)
        if (data) {
            if (data?.can_use === true) {
                if (isEmpty(code)) {
                    await apiSendEmailConfirmCode(email)
                }
                if (referral) {
                    const {data: referralRes} = await apiCheckReferral(referral)
                    if (referralRes?.is_valid) {
                        navigate('/register_code')
                    } else {
                        setReferralInputState(InputState.ERROR)
                        setReferralInputErrorMessage(t('loginModal.registerReferral.submit.res.fail.wrongCode'))
                    }
                } else {
                    navigate('/register_code')
                }
            } else {
                setInputState(InputState.ERROR)
                setInputErrorMessage(t('loginModal.registerName.submit.res.fail.nameDuplication'))
                createLog('error', 'login_email_register_name_submit_failed_nameDuplication')
            }
        } else {
            showErrorSnackbar(error)
            createLog('error', 'login_email_register_name_submit_failed')
        }
        setIsLoading(false)
    }

    const onChangedInputText = (text?: string) => {
        setName(text)
        if (ENGLISH_NICKNAME_FORBIDDEN_REGEX.test(text)) {
            setInputState(InputState.ERROR)
        } else {
            setInputErrorMessage(t('loginModal.registerName.name.input.description'))
            setInputState(InputState.DEFAULT)
        }
    }

    const onChangedInputReferral = (code?: string) => {
        setReferral(code)
        setReferralInputState(InputState.DEFAULT)
    }

    useEffect(() => {
        if (router?.query?.referral) {
            setReferral(router?.query?.referral as string)
        }
    }, [router?.query])

    return (
        <LoginModalLayout closeModal={closeModal} title={t('loginModal.registerName.page.title')}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 break-words'}>
                {t('loginModal.registerName.desc')}
            </Text>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.registerName.name.label')}
            </Text>
            <WizInputText
                text={name}
                className={'mt-[5px]'}
                state={inputState}
                autoFocus={true}
                errorDescription={inputErrorMessage}
                placeholder={t('loginModal.registerName.name.input.placeholder')}
                defaultDescription={t('loginModal.registerName.name.input.description')}
                renderInputRightArea={
                    <InputTextCount text={name} maxCount={NICKNAME_MAX_LENGTH} minLength={NICKNAME_MIN_LENGTH} />
                }
                onChanged={onChangedInputText}
                onPressEnter={onClickSubmit}
            />
            {!router?.query?.referral && (
                <div className={'mt-[20px]'}>
                    <div
                        className={'flex items-center gap-[5px] cursor-pointer'}
                        onClick={() => {
                            setIsOpenReferral(prev => !prev)
                        }}>
                        <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                            {t('loginModal.registerName.referral.title')}
                        </Text>
                        <IconArrowDown
                            className={`w-[16px] fill-gray02 dark:fill-dark_gray02 ${
                                isOpenReferral && 'rotate-[180deg]'
                            }`}
                        />
                    </div>
                    {isOpenReferral && (
                        <div>
                            <WizInputText
                                text={referral}
                                className={'mt-[5px]'}
                                state={referralInputState}
                                errorDescription={referralInputErrorMessage}
                                placeholder={t('loginModal.registerName.referral.placeholder')}
                                onChanged={onChangedInputReferral}
                                onPressEnter={onClickSubmit}
                            />
                            <ul className={'mt-[5px] list-disc list-outside pl-[15px]'}>
                                <li className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                    <TagText>{t('loginModal.registerName.referral.desc1')}</TagText>
                                </li>
                                <li className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                    <TagText>{t('loginModal.registerName.referral.desc2')}</TagText>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            )}
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.registerName.submit.text')}
                disabled={isDisableSubmitButton}
                className={'mt-[20px] w-full'}
                onClick={onClickSubmit}
                isLoading={isLoading}
            />
        </LoginModalLayout>
    )
}

export default ScreenRegisterName
