import React, {useContext, useEffect, useRef, useState} from 'react'
import Text from '@component/Text/Text'
import IconClose from '@svg/common/ic_close.svg'
import {useRouter} from 'next/router'
import useFormatDate from '@hook/useFormatDate'
import Link from 'next/link'
import {IFloatingMessage} from '@api/event/getApiFloating'
import useQueryGetFloatingMessage from '@hook/query/event/useQueryGetFloatingMessage'
import {useLoginStore} from '@store/LoginStore'
import {ModalContext} from '@component/core/ModalContainer'
import {createLog} from '@util/logs'

type TypeList = {
    id: string
    end_at: string
    is_view: boolean
}

interface IProps {
    className?: string
}

const FloatingMessage = ({className}: IProps) => {
    const [messageItem, setMessageItem] = useState<IFloatingMessage>()
    const {getTodayUTC0} = useFormatDate()
    const [isHidden, setIsHidden] = useState(true)
    const router = useRouter()
    const {isShowDailyReport} = useContext(ModalContext)
    const loginId = useLoginStore(state => state?.id)

    const {data, refetch} = useQueryGetFloatingMessage(loginId)

    useEffect(() => {
        refetch()
    }, [loginId])

    useEffect(() => {
        // 만료된 데이터 확인 후 삭제
        const floatingData = JSON.parse(localStorage.getItem('floatingMessage') || '[]') as TypeList[]
        if (floatingData?.length > 0) {
            const removeList = floatingData?.filter(l => {
                const now = new Date()
                return now.getTime() <= Number(l.end_at) * 1000
            })
            localStorage?.setItem('floatingMessage', JSON.stringify(removeList))
            if (removeList?.length > 0) {
                localStorage?.setItem('floatingMessage', JSON.stringify(removeList))
            }
        }
    }, [])

    useEffect(() => {
        const floatingData = JSON.parse(localStorage.getItem('floatingMessage') || '[]') as TypeList[]
        const nowUTC0 = String(getTodayUTC0()?.getTime())

        if (
            data?.length > 0 &&
            localStorage.getItem('lastFloatingMessageTime') !== nowUTC0 &&
            isShowDailyReport === false
        ) {
            const currentTime = new Date().getTime()
            // 만약 localStorage 에 is_today_floating_message가 있고 날짜의 day가 오늘과 같다면
            const sortedData = data
                ?.filter(item => {
                    return router?.asPath?.includes(item?.action_url?.web) && item?.action_url?.web
                })
                ?.sort((a, b) => b.priority - a.priority)
                ?.find(item => {
                    const isViewed = floatingData.some(i => i.id === item.id && i?.is_view)
                    const isLanguageMismatch = item.language ? item.language !== router?.locale : false
                    const isExpired = item.end_at * 1000 <= currentTime
                    const isStarted = item.start_at * 1000 > currentTime

                    if (isViewed || isLanguageMismatch || isStarted || isExpired) {
                        return false
                    }
                    return true
                })

            setIsHidden(true)

            if (sortedData && router?.asPath?.includes(sortedData.action_url?.web)) {
                setTimeout(() => {
                    // 보여줬다면 is_today_floating_message를 오늘 day로 갱신을 animation callback으로 넣음
                    animatedRef?.current?.classList?.add('animate-[floatingMsg_5s_ease-in-out]')
                    mobileAnimatedRef?.current?.classList?.add('animate-[mobileFloatingMsg_5s_ease-in-out]')
                    setMessageItem(sortedData)
                    createLog('event', 'floating_message_show')
                    localStorage.setItem('lastFloatingMessageTime', nowUTC0)

                    if (floatingData) {
                        localStorage.setItem(
                            'floatingMessage',
                            JSON.stringify([
                                {
                                    id: sortedData?.id,
                                    end_at: sortedData?.end_at,
                                    is_view: true,
                                },
                                ...floatingData,
                            ]),
                        )
                    } else {
                        localStorage.setItem(
                            'floatingMessage',
                            JSON.stringify([
                                {
                                    id: sortedData?.id,
                                    end_at: sortedData?.end_at,
                                    is_view: true,
                                },
                            ]),
                        )
                    }
                }, 2000)
            }
        } else {
            if (data?.length > 0) {
                setIsHidden(false)
            }
        }
    }, [router, data, isShowDailyReport])

    const resetAnimation = () => {
        createLog('event', 'floating_message_click_close')
        animatedRef?.current?.classList?.remove('animate-[floatingMsg_5s_ease-in-out]')
        mobileAnimatedRef?.current?.classList?.remove('animate-[mobileFloatingMsg_5s_ease-in-out]')
        animatedRef?.current?.classList?.add('animate-[fadeOutMessage_0.4s_ease]')
        mobileAnimatedRef?.current?.classList?.add('animate-[fadeOutMessage_0.4s_ease]')
    }

    const animatedRef = useRef<HTMLDivElement>()
    const mobileAnimatedRef = useRef<HTMLDivElement>()

    if (!messageItem || !isHidden || isShowDailyReport === true) return null

    return (
        <div
            className={`fixed top-[50px] right-[0px] sm:top-auto sm:bottom-[0px] sm:right-auto sm:left-auto overflow-hidden w-[450px] h-[145px] sm:h-[115px] z-dropdown sm:w-full`}>
            <div
                ref={animatedRef}
                onAnimationEnd={() => {
                    animatedRef?.current?.classList?.remove('animate-[floatingMsg_5s_ease-in-out]')
                    animatedRef?.current?.classList?.remove('animate-[fadeOutMessage_0.4s_ease]')
                    mobileAnimatedRef?.current?.classList?.remove('animate-[mobileFloatingMsg_5s_ease-in-out]')
                    mobileAnimatedRef?.current?.classList?.remove('animate-[fadeOutMessage_0.4s_ease]')
                    setIsHidden(false)
                }}
                className={`sm:!hidden ${
                    isHidden ? 'animate-[floatingMsg_5s_ease-in-out]' : ''
                } absolute top-[30px] opacity-0 flex right-[30px] pl-[20px] py-[15px] pr-[10px] gap-x-[7px] items-center w-[400px] border border-gray07 dark:border-dark_gray07 shadow-hover rounded-[5px] bg-white dark:bg-bg_dark_popup ${className} z-tooltip`}>
                <Text className={'text-h4'}>{messageItem?.emoji}</Text>
                <Text className={'text-body2 text-gray01 dark:text-dark_gray01 grow'}>
                    {router?.locale === 'ko' ? messageItem?.text?.ko : messageItem?.text?.en}
                </Text>
                <div className={'flex items-center gap-x-[9px] pr-[4px]'}>
                    {(!messageItem?.language ||
                        (messageItem?.language === 'ko' && messageItem?.button_text?.ko) ||
                        (messageItem?.language === 'en' && messageItem?.button_text?.en)) && (
                        <Link
                            href={
                                router?.locale === 'ko'
                                    ? messageItem?.button_url?.web_ko || ''
                                    : messageItem?.button_url?.web_en || ''
                            }>
                            <button
                                className={
                                    'bg-primary dark:bg-dark_primary py-[8.5px]  px-[15px] rounded-full cursor-pointer'
                                }>
                                <Text className={'text-ti3 text-white whitespace-nowrap'} enablePreWhiteSpace={false}>
                                    {router?.locale === 'ko'
                                        ? messageItem?.button_text?.ko
                                        : messageItem?.button_text?.en}
                                </Text>
                            </button>
                        </Link>
                    )}
                    <div onClick={() => resetAnimation()}>
                        <IconClose className={'w-[16px] fill-gray02 dark:fill-dark_gray02 cursor-pointer'} />
                    </div>
                </div>
            </div>
            <div
                ref={mobileAnimatedRef}
                onAnimationEnd={() => {
                    mobileAnimatedRef?.current?.classList?.remove('animate-[mobileFloatingMsg_5s_ease-in-out]')
                    setIsHidden(true)
                }}
                className={`!hidden sm:!flex ${
                    isHidden ? 'animate-[mobileFloatingMsg_5s_ease-in-out]' : ''
                } absolute bottom-[20px] left-[50%] translate-x-[-50%] opacity-0 overflow-hidden pl-[15px] py-[15px] pr-[10px] gap-x-[7px] items-center sm:w-[calc(100%-40px)] border border-gray07 dark:border-dark_gray07 shadow-hover rounded-[5px] bg-white dark:bg-bg_dark_popup ${className} z-dropdown`}>
                <Text className={'text-h4'}>{messageItem?.emoji}</Text>
                <Text className={'text-body2 text-gray01 dark:text-dark_gray01 grow'}>
                    {router?.locale === 'ko' ? messageItem?.text?.ko : messageItem?.text?.en}
                </Text>
                <div className={'flex items-center gap-x-[13px] pr-[3px]'}>
                    {(!messageItem?.language ||
                        (messageItem?.language === 'ko' && messageItem?.button_text?.ko) ||
                        (messageItem?.language === 'en' && messageItem?.button_text?.en)) && (
                        <Link
                            onClick={() => {
                                createLog('event', 'floating_message_click_button')
                            }}
                            href={
                                router?.locale === 'ko'
                                    ? messageItem?.button_url?.web_ko || ''
                                    : messageItem?.button_url?.web_en || ''
                            }>
                            <button
                                className={
                                    'bg-primary dark:bg-dark_primary py-[8.5px] px-[15px] rounded-full cursor-pointer'
                                }>
                                <Text className={'text-ti3 text-white'}>
                                    {router?.locale === 'ko'
                                        ? messageItem?.button_text?.ko
                                        : messageItem?.button_text?.en}
                                </Text>
                            </button>
                        </Link>
                    )}
                    <div onClick={() => resetAnimation()}>
                        <IconClose className={'w-[13px] fill-gray02 dark:fill-dark_gray02 cursor-pointer'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(FloatingMessage)
