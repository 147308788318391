import React from 'react'
import Script from 'next/script'

const ChartInitializer = () => {
    return (
        <React.Fragment>
            <Script src="/static/charting_library/charting_library.js" strategy="beforeInteractive" />
        </React.Fragment>
    )
}

export default ChartInitializer
